import React from "react";
import styled from "styled-components";
import Card from "components/Card";
import PieGraph from "graphs/PieGraph";

const Div = styled(Card)`
  margin-top: 2em;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 28.75em;
    max-width: 28.75em;
  }
`;

const LoadDistribution = ({ data }) => (
  <Div>
    <h3>Load Distribution</h3>
    <PieGraph
      data={data}
      dataKey="val"
      text={`${data.reduce((a, b) => a + (b.val || 0), 0).toLocaleString()} Load`}
    />
  </Div>
);

export default LoadDistribution;
