export const rnd = (min, max, round = true) => {
  const r = Math.random() * (max - min) + min;
  return round ? Math.ceil(r) : r;
};

export const getSeqDates = (n, options, local = "en-us") => {
  let arr = [];
  for (let i = 0; i < n; i++) {
    let date = new Date();
    date.setDate(date.getDate() - i);
    arr.unshift(date.toLocaleString(local, options));
  }
  return arr;
};

export const makeArray = (n) => {
  let arr = [];
  for (let i = 0; i < n; i++) arr.push(i);
  return arr;
};
