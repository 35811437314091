import React, { memo } from "react";

const UsersIcon = (props) => (
  <svg width="1em" viewBox="0 0 20.997 15.748" {...props}>
    <path
      fillRule="evenodd"
      d="M9.186 15.748a1.285 1.285 0 0 1-1.312-1.312c0-1.312 1.312-5.249 6.562-5.249S21 13.123 21 14.437a1.285 1.285 0 0 1-1.312 1.311zm5.25-7.874a3.937 3.937 0 1 0-2.785-1.153 3.937 3.937 0 0 0 2.784 1.153zm-7.591 7.874a2.937 2.937 0 0 1-.283-1.312A6.263 6.263 0 0 1 9.1 9.554a8.3 8.3 0 0 0-2.54-.367c-5.248 0-6.561 3.936-6.561 5.25a1.285 1.285 0 0 0 1.312 1.311zm-.94-7.874a3.281 3.281 0 1 0-2.32-.96 3.281 3.281 0 0 0 2.32.96z"
    />
  </svg>
);

export default memo(UsersIcon);
