import React from "react";
import styled from "styled-components";
import Card from "components/Card";
import useInput from "utils/useInput";
import Flex from "components/Flex";
import DropDown from "components/DropDown";
import MultiBarsGraph from "graphs/MultiBarsGraph";

const Div = styled(Card)`
  margin-top: 2em;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.md} {
    width: 30.5em;
    max-width: 30.5em;
  }
`;

const H3 = styled.h3`
  margin-bottom: 1em;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin: 0;
  }
`;

const options = ["Week", "Week 2", "Week 3", "Week 4"];

const WeekToWeek = ({ data }) => {
  const select1 = useInput("select1", "Week");
  const select2 = useInput("select2", "Week");
  const select3 = useInput("select3", "Week");
  return (
    <Div>
      <Flex fd="column" xs={{ fd: "row", jc: "space-between", ai: "center" }}>
        <H3>Week to Week</H3>
        <Flex jc="space-between" xs={{ jc: "flex-end" }}>
          <DropDown w="6em" {...select1} options={options} />
          <DropDown w="6em" {...select2} options={options} />
          <DropDown w="6em" {...select3} options={options} />
        </Flex>
      </Flex>
      <MultiBarsGraph
        data={data}
        dataKey="day"

        min1={10}
        max1={30}
        min2={20}
        max2={100}
        unit1="K"
        unit2="K"

        name1="Week 1"
        key1="d1"

        name2="Week 2"
        key2="d2"

        name3="Week 3"
        key3="d3"

      />
    </Div>
  );
};

export default WeekToWeek;
