const breakpoints = {
  xs: "(min-width: 25.750em)", //            416                    Big Phones
  sm: "(min-width: 48em)", //            768 x 576      Tablet
  md: "(min-width: 64em)", //           1024 x 768      Laptop
  lg: "(min-width: 85.375em)", //  1366 x 768      Laptop Large
  fhd: "(min-width: 120em)", //         1920 x 1080    Desktop 1080p
  qhd: "(min-width: 160em)", //     2560 x 1440     Desktop 1440p
  uhd: "(min-width: 240em)", //     3840 x 2560    Desktop 4K
};

export default breakpoints;
