import React, { memo } from "react";

const ListIcon = (props) => (
  <svg height="1em" viewBox="0 0  15.91 11.785" {...props}>
    <path
      fillRule="evenodd"
      d="M.589 0A.589.589 0 0 0 0 .589v1.18a.589.589 0 0 0 .589.588h1.179a.589.589 0 0 0 .589-.59V.59A.589.589 0 0 0 1.768 0zm1.179.589H.589v1.18h1.179z"
    />
    <path d="M4.125 1.178a.589.589 0 0 1 .589-.589h10.607a.59.59 0 1 1 0 1.179H4.714a.589.589 0 0 1-.59-.59zm.589 4.125a.59.59 0 1 0 0 1.179h10.607a.59.59 0 1 0 0-1.179zm0 4.714a.59.59 0 1 0 0 1.179h10.607a.59.59 0 1 0 0-1.179z" />
    <path
      fillRule="evenodd"
      d="M0 5.303a.589.589 0 0 1 .589-.589h1.179a.589.589 0 0 1 .589.589v1.18a.589.589 0 0 1-.589.588H.59A.589.589 0 0 1 0 6.482zm.589 0h1.179v1.179H.589zm0 4.125a.589.589 0 0 0-.589.589v1.18a.589.589 0 0 0 .589.592h1.179a.589.589 0 0 0 .589-.589v-1.183a.589.589 0 0 0-.589-.589zm1.179.589H.589v1.18h1.179z"
    />
  </svg>
);

export default memo(ListIcon);
