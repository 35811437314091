import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Flex from "./Flex";

const Element = styled(Flex)`
  cursor: pointer;
  margin: ${({ margin }) => margin};
  height: ${({ h }) => h || "3.125em"};
  width: ${({ w }) => w || "11.875em"};
  border-radius: 0.25em;
  background: ${({ theme, border }) => (border ? "" : theme.colors.buttonBlue)};
  border: solid 0.063em ${({ theme, border }) => border && theme.colors[border]};
  color: ${({ theme, border }) => theme.colors[border ? border : "buttonText"]};
  span {
    font-size: ${({ fz }) => fz || "1.125em"};
  }
`;

const Button = ({ text, ...props }) => (
  <Element jc="center" ai="center" as={props.to ? Link : props.href ? "a" : "button"} {...props}>
    <span>{text}</span>
  </Element>
);

export default Button;
