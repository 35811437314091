import React from "react";
import styled from "styled-components";
import Card from "components/Card";
import Flex from "components/Flex";
import CheckBox from "components/CheckBox";
import useToggle from "utils/useToggle";
import useInput from "utils/useInput";
import Input from "components/Input";
import Button from "components/Button";

const Div = styled(Card)`
  margin-top: 2.125em;
`;

const Wrapper = styled(Flex)`
  > * {
    margin-top: 1.5em;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 1.5em;
    > * {
      margin-top: 0;
      flex: 1 1 49%;
    }
  }
`;

const CheckBoxWrapper = styled(Flex)`
  margin-top: -0.875em;
  > * {
    flex: 1 1 50%;
    margin-top: 0.875em;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    > * {
      flex: 1 1 25%;
    }
  }
`;

const Filters = () => {
  const [checked1, toggleChecked1] = useToggle();
  const [checked2, toggleChecked2] = useToggle();
  const [checked3, toggleChecked3] = useToggle();
  const [checked4, toggleChecked4] = useToggle();
  const [checked5, toggleChecked5] = useToggle(true);
  const [checked6, toggleChecked6] = useToggle(true);
  const [checked7, toggleChecked7] = useToggle(true);
  const [checked8, toggleChecked8] = useToggle(true);
  // Input
  const searchInput = useInput("search");
  return (
    <Div>
      <h3>Filters</h3>
      <Wrapper fd="column" sm={{ fd: "row", jc: "space-between" }}>
        <div>
          <CheckBoxWrapper fw="wrap">
            <CheckBox checked={checked1} onChange={toggleChecked1} label="Select" />
            <CheckBox checked={checked2} onChange={toggleChecked2} label="Select" />
            <CheckBox checked={checked3} onChange={toggleChecked3} label="Select" />
            <CheckBox checked={checked4} onChange={toggleChecked4} label="Select" />
            <CheckBox checked={checked5} onChange={toggleChecked5} label="Selected" />
            <CheckBox checked={checked6} onChange={toggleChecked6} label="Selected" />
            <CheckBox checked={checked7} onChange={toggleChecked7} label="Selected" />
            <CheckBox checked={checked8} onChange={toggleChecked8} label="Selected" />
          </CheckBoxWrapper>
        </div>
        <div>
          <Input {...searchInput} h="2.286em" />
          <Button
            text="Filter"
            w="6.25em"
            h="1.875em"
            border="buttonBlue"
            margin="0.75em 0 0 auto"
          />
        </div>
      </Wrapper>
    </Div>
  );
};

export default Filters;
