import React, { memo } from "react";
import styled from "styled-components";
import Overlay from "./Overlay";
import DropDownTrigger from "./DropDownTrigger";
import OptionsList from "./OptionsList";
import useToggle from "utils/useToggle";

const Div = styled.div`
  position: relative;
  max-width: ${({ mw }) => (mw ? mw : "auto")};
  user-select: none;
`;

const DropDown = ({ onChange, ...props }) => {
  const [open, toggle] = useToggle(false);
  const updateVal = (value) => {
    if (onChange) onChange({ target: { value } });
    toggle();
  };
  return (
    <Div {...props}>
      <DropDownTrigger open={open} onClick={toggle} {...props} />
      <Overlay active={open} onClick={toggle} />
      <OptionsList open={open} updateVal={updateVal} {...props} />
    </Div>
  );
};

export default memo(DropDown);
