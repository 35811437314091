import breakpoints from "./breakpoints";
import useToggle from "utils/useToggle";
import lightColors from "./lightColors";
import darkColors from "./darkTheme";
import { useEffect } from "react";

const useDynamicTheme = () => {
  const [darkActive, toggleTheme] = useToggle(localStorage.getItem("theme") === "dark");
  useEffect(() => {
    localStorage.setItem("theme", darkActive ? "dark" : "light");
  }, [darkActive]);
  return {
    breakpoints,
    toggleTheme,
    themeName: darkActive ? "dark" : "light",
    altTheme: darkActive ? "light" : "dark",
    colors: darkActive ? darkColors : lightColors,
  };
};

export default useDynamicTheme;
