import React from "react";
import styled from "styled-components";
import Flex from "./Flex";

const Div = styled(Flex)`
  border-bottom: solid ${({ theme }) => theme.colors.tabsBorder} 0.063em;
`;
const Tab = styled.div`
  cursor: pointer;
  padding: 0 1em 0.5em 1em;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  color: ${({ theme, active }) => active && theme.colors.tabsActive};
  border-bottom: solid ${({ theme, active }) => (active ? theme.colors.tabsActive : "transparent")}
    0.063em;
  margin-bottom: -0.063em;
`;

const Tabs = ({ tabs, activeIndex, update }) => {
  return (
    <Div jc="flex-start">
      {tabs.map((tab, index) => (
        <Tab key={index} active={index === activeIndex} onClick={() => update(index)}>
          {tab}
        </Tab>
      ))}
    </Div>
  );
};

export default Tabs;
