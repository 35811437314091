import React from "react";
import styled from "styled-components";
import Flex from "./Flex";

const Label = styled(Flex)`
  display: inline-flex;
  margin: ${({ margin }) => margin};
  width: ${({ w }) => w};
  input {
    display: none;
  }
`;

const Dot = styled(Flex)`
  margin-right: 0.625em;
  width: 1.875em;
  height: 1.875em;
  border: solid 0.188em
    ${({ theme, checked }) => theme.colors[checked ? "checkboxActive" : "checkboxBorder"]};
  color: ${({ theme }) => theme.colors.checkboxActive};
  border-radius: 100%;
  div {
    display: ${({ checked }) => (checked ? "block" : "none")};
    width: 0.875em;
    height: 0.875em;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.checkboxActive};
  }
`;

const RadioInput = ({ label, ...props }) => (
  <Label {...props} as="label" ai="center">
    <input {...props} type="radio" />
    <Dot jc="center" ai="center" checked={props.checked}>
      <div />
    </Dot>
    {label && <p>{label}</p>}
  </Label>
);

export default RadioInput;
