import React, { memo } from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import ChevronIcon from "icons/ChevronIcon";

const Div = styled(Flex)`
  border: solid 0.063em
    ${({ theme, open }) => theme.colors[open ? "dropdownOpenBorder" : "dropdownBorder"]};
  border-radius: 0.25em;
  box-shadow: 0 0.125em 0.188em ${({ theme }) => theme.colors.dropdownShadow};
  position: relative;
  cursor: pointer;
  height: ${({ h }) => (h ? h : "2em")};
  width: ${({ w }) => (w ? w : "auto")};
  max-width: ${({ mw }) => (mw ? mw : "auto")};
  padding: 0 0.75em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  input {
    margin: 0 !important;
    padding: 0 !important;
    width: auto;
    min-width: 2em;
    flex: 1 1 2em;
    cursor: pointer;
    font-size: ${({ fz }) => (fz ? fz : "0.875em")};
    border: 0;
    color: ${({ theme }) => theme.colors.bodyColor};
    &:focus,
    &:invalid {
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.bodyColor};
    }
  }
  svg {
    font-size: 0.833em;
    margin-left: 0.417em;
    color: ${({ theme, open }) => theme.colors[open ? "dropdownOpenChevron" : "dropdownChevron"]};
    transform: rotate(${({ open }) => (open ? 180 : 0)}deg);
  }
`;

const DropDownTrigger = ({ value, placeholder, open, required, ...props }) => {
  const handleChange = () => {};
  return (
    <Div jc="space-between" ai="center" open={open} {...props}>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        required={required}
      />
      <ChevronIcon />
    </Div>
  );
};

export default memo(DropDownTrigger);
