import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import Logo from "components/Layout/Logo";
import MobileMenu from "./MobileMenu";
import UserSide from "./UserSide";

const Div = styled(Flex)`
  height: 4.5em;
  width: 100%;
  background: ${({ theme }) => theme.colors.headerBg};
  box-shadow: 0 0.125em 0.188em ${({ theme }) => theme.colors.headerShadow};
  padding: 0 0 0 1em;
  position: relative;
  z-index: 100;
  @media ${({ theme }) => theme.breakpoints.lg} {
    padding: 0;
  }
`;

const Header = ({ adminNav }) => {
  return (
    <Div jc="stretch" ai="center" lg={{ jc: "space-between" }}>
      <MobileMenu adminNav={adminNav} />
      <Logo />
      <UserSide />
    </Div>
  );
};

export default Header;
