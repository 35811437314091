import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import Logo from "components/Layout/Logo";

const Div = styled(Flex)``;

const H1 = styled.h1`
  margin-top: 5em;
  font-size: 2.5em;
`;

const NotFound = () => {
  document.title = "Athleta Pro | Page Not Found";
  return (
    <Div fd="column" ai="center">
      <Logo />
      <H1>404: Page Not Found</H1>
    </Div>
  );
};

export default NotFound;
