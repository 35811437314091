import React, { memo } from "react";

const SunIcon = (props) => (
  <svg height="1em" viewBox="0 0 25.327 25.327" {...props}>
    <path d="M12.664 18.996a6.332 6.332 0 1 0-6.332-6.332 6.332 6.332 0 0 0 6.332 6.332z" />
    <path
      fillRule="evenodd"
      d="M12.664 0a.791.791 0 0 1 .79.791v3.166a.791.791 0 1 1-1.582 0V.791a.791.791 0 0 1 .792-.789zm0 20.58a.791.791 0 0 1 .79.79v3.166a.791.791 0 1 1-1.582 0V21.37a.791.791 0 0 1 .791-.79zm12.664-7.916a.791.791 0 0 1-.79.79H21.37a.791.791 0 1 1 0-1.582h3.166a.791.791 0 0 1 .79.791zm-20.579 0a.791.791 0 0 1-.791.79H.79a.791.791 0 1 1 0-1.582h3.166a.791.791 0 0 1 .791.791zm16.87-8.955a.791.791 0 0 1 0 1.12l-2.24 2.234a.791.791 0 0 1-1.118-1.12l2.24-2.237a.791.791 0 0 1 1.121 0zM7.066 18.261a.791.791 0 0 1 0 1.119l-2.238 2.24A.791.791 0 0 1 3.71 20.5l2.236-2.238a.791.791 0 0 1 1.12 0zm14.552 3.357a.791.791 0 0 1-1.119 0l-2.238-2.238a.791.791 0 0 1 1.12-1.12l2.238 2.24a.791.791 0 0 1 0 1.12zM7.066 7.066a.791.791 0 0 1-1.119 0L3.71 4.828a.791.791 0 0 1 1.12-1.12l2.236 2.24a.791.791 0 0 1 0 1.118z"
    />
  </svg>
);

export default memo(SunIcon);
