const white = "#FFF";
const black = "#000";
const blue = "#266ABB";

const lightColors = {
  // Global Styles
  selectionColor: white,
  selectionBg: "rgba(38, 106, 187, 0.25)",

  bodyBg: white,
  bodyColor: "#43425D",

  heading: "#231F20",

  headerBg: white,
  headerShadow: "rgba(0, 0, 0, 0.05)",
  headerUsername: "#4D4F5C",
  headerChevron: "#A4AFB7",

  logo: "#231f20",
  logoAlt: blue,

  navBg: "#F6F6F6",
  navText: "#909090",
  navTextHover: blue,
  navActiveText: white,
  navActiveBg: "rgba(38, 106, 187, 0.25)",
  navActiveBorder: "#3B86FF",

  modalBg: "rgba(0, 0, 0, 0.5)",

  // Components
  cardBg: white,
  cardShadow: "rgba(0, 0, 0, 0.04)",

  tableHeadBg: "#F5F6FA",
  tableHeadText: "#A3A6B4",
  tableBorder: "#F1F1F3",
  tableHighlight: "rgba(144, 144, 144, 0.15)",
  tableActive: "#3B86FF",
  tableSelected: "rgba(38, 106, 187, 0.1)",

  dropdownBg: white,
  dropdownBorder: "#D7DAE2",
  dropdownListBorder: "#E9E9F0",
  dropdownOpenBorder: blue,
  dropdownShadow: "rgba(0, 0, 0, 0.05)",
  dropdownChevron: "#A4AFB7",
  dropdownOpenChevron: blue,
  dropdownOptionHover: "#E9E9F0",

  tabsBorder: "#E9E9F0",
  tabsActive: "#3B86FF",

  checkboxBorder: "#C3DAFF",
  checkboxActive: "#3B86FF",

  inputBorder: "#E8E9EC",
  inputBg: white,
  inputPlaceholder: "#CECFD0",
  inputActive: "#3B86FF",

  buttonBlue: "#3B86FF",
  buttonRed: "#FF3B3B",
  buttonText: white,

  paginationBorder: "#E8E9EC",
  paginationArrows: "#808495",
  paginationActive: "#3B86FF",
  paginationActiveText: white,

  // Graphs
  simpleBarGraph: "#266ABB",
  simpleBarGraphAlt: "#73A1C2",

  graphGrid: "#EAF0F4",

  radarRed: "#FF4949",
  radarBlue: "#3B86FF",
  radarPurple: "#A3A0FB",

  stackedPurple: "#A3A0FB",
  stackedAqua: "#56D9FE",
  stackedRed: "#FF8373",
  stackedYellow: "#FFDA83",

  pie0: "#56D9FE",
  pie1: "#A3A0FB",
  pie2: "#FFDA83",
  pie3: "#FF8373",

  multiBars1: "#A4A1FB",
  multiBars2: "#56D9FE",
  multiBars3: "#5FE3A1",

  composedBar: "#F0F2F8",
  composedLine: "#3B7AD9",

  // Colors
  red: "#FF4141",
  green: "#3CC480",

  // Home
  homeLink: white,
  homeLinkBg: black,
  homeLinkHoverBg: blue,

  // Dashboard
  dashboardOverviewIcon: "#73a1c2",
  dashboardOverviewIcon2: "#A4AFB7",

  quickDetailsIconBg: "rgba(144, 144, 144, 0.2)",
  quickDetailsIcon: blue,
  quickDetailsBorder: "#F1F1F3",

  // Form Builder
  formLayoutFieldBorder: "#F1F1F3",
  formLayoutFieldPen: "#63E96C",
  formLayoutFieldCross: "#FF6565",

  formLayoutFieldActive: "#3B86FF",
  formLayoutFieldSelected: "rgba(38, 106, 187, 0.1)",
};

export default lightColors;
