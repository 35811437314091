import React, { useState } from "react";
import styled from "styled-components";
import Card from "components/Card";
import Flex from "components/Flex";
import Pagination from "components/Pagination";
import wellnessData from "./wellnessData";
import ResponsiveTable from "components/ResponsiveTable";

const Div = styled(Card)`
  margin-top: 3.125em;
`;
const H3 = styled.h3`
  margin-bottom: 1em;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin: 0;
  }
`;

const Wellness = () => {
  const [currentIndex, selectPage] = useState(0);
  const [selectedId, selectId] = useState(0);
  return (
    <Div>
      <Flex fd="column" xs={{ fd: "row", jc: "space-between", ai: "center" }}>
        <H3>Week to Week</H3>
        <Pagination
          currentIndex={currentIndex}
          update={selectPage}
          perPage={7}
          dataCount={wellnessData.body.length}
        />
      </Flex>
      <ResponsiveTable
        header={wellnessData.header}
        body={wellnessData.body.slice(currentIndex * 7, (currentIndex + 1) * 7)}
        selectedId={selectedId}
        selectId={selectId}
      />
    </Div>
  );
};

export default Wellness;
