import React, { useState } from "react";
import styled from "styled-components";
import Card from "components/Card";
import ResponsiveTable from "components/ResponsiveTable";
import programData from "./programData";
import Flex from "components/Flex";
import DropDown from "components/DropDown";
import useInput from "utils/useInput";

const Div = styled(Card)`
  margin-top: 1.25em;
`;

const options = programData.weeks.map((_, i) => `Week ${i + 1}`);

const ProgramDetails = ({ programName }) => {
  const ProgramDropdown = useInput("weeks", "Week 1");
  const weekIndex = parseInt(ProgramDropdown.value.replace(/\D/gm, "")) - 1;

  const [data, setData] = useState(programData.weeks);
  const update = ({ key, value, id }) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData[weekIndex][id][key] = value;
    setData(newData);
  };
  return (
    <Div>
      <Flex jc="space-between">
        <h3>{programName}</h3>
        <DropDown {...ProgramDropdown} placeholder="Program Dropdown" options={options} w="10em" />
      </Flex>
      <ResponsiveTable header={programData.header} body={data[weekIndex]} update={update} />
    </Div>
  );
};

export default ProgramDetails;
