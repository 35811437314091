import { rnd } from "utils/dataGenerators";

const generateDate = (n = 10, max, min = 0) => {
  let arr = [];
  for (let i = 0; i < n; i++)
    arr.unshift({
      key: i,
      val: rnd(min, max),
    });

  return arr;
};

const mainOverviewData = {
  diff: [
    { title: "ACWR", value: "1.03", change: -13.8, key: 0, data: generateDate(13, 40, 13) },
    { title: "Week to Week", value: "2453", change: 13.8, key: 1, data: generateDate(13, 40, 13) },
    { title: "Percentage", value: "$39K", change: -13.8, key: 2, data: generateDate(13, 40, 13) },
  ],
  wellness: "76%",
  attendance: "86%",
  gameAvailability: "98%",
};

export default mainOverviewData;
