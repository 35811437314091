import React, { useState } from "react";
import styled from "styled-components";
import Card from "components/Card";
import FormLayoutField from "./FormLayoutField";
import Button from "components/Button";
import Flex from "components/Flex";
import { makeArray } from "utils/dataGenerators";

const Div = styled(Card)`
  margin-top: 3.75em;
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 40em;
    max-width: 48%;
  }
`;

const FormLayout = () => {
  const [selected, setSelected] = useState(0);
  return (
    <Div as={Flex} fd="column" jc="space-between">
      <div>
        <h3>Form Layout</h3>
        {makeArray(5).map((key) => (
          <FormLayoutField key={key} selected={key === selected} onClick={() => setSelected(key)} />
        ))}
      </div>
      <Button text="Generate Form" margin="4em auto 0 auto" />
    </Div>
  );
};

export default FormLayout;
