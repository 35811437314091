import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import applyEMRatio from "utils/applyEMRatio";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar } from "recharts";

const GraphWrapper = styled.div`
  width: 100%;
  height: calc(22em - 2.25em);
`;

const StackedBarChart = ({
  data,
  dataKey,
  maxValue,
  color1,
  name1,
  key1,
  color2,
  name2,
  key2,
  color3,
  name3,
  key3,
  color4,
  name4,
  key4,
  ...props
}) => {
  const { colors } = useContext(ThemeContext);
  return (
    <GraphWrapper {...props}>
      <ResponsiveContainer>
        <BarChart
          width={applyEMRatio(1450)}
          height={applyEMRatio(320)}
          data={data}
          barSize={applyEMRatio(32)}
        >
          <CartesianGrid stroke={colors.graphGrid} vertical={false} />
          <XAxis dataKey={dataKey} tickLine={false} axisLine={false} />
          <YAxis
            tickLine={false}
            axisLine={false}
            domain={[0, maxValue || "dataMax"]}
            tickCount={6}
            width={applyEMRatio(40)}
          />
          <Legend verticalAlign="top" height={applyEMRatio(64)} />
          <Bar dataKey={key1} name={name1} stackId="a" fill={colors[color1 || "stackedYellow"]} />
          <Bar dataKey={key2} name={name2} stackId="a" fill={colors[color2 || "stackedRed"]} />
          <Bar dataKey={key3} name={name3} stackId="a" fill={colors[color3 || "stackedAqua"]} />
          <Bar
            dataKey={key4}
            name={name4}
            stackId="a"
            fill={colors[color4]}
            radius={[applyEMRatio(5), applyEMRatio(5), 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

export default StackedBarChart;
