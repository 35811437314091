import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import applyEMRatio from "utils/applyEMRatio";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar } from "recharts";

const GraphWrapper = styled.div`
  width: 100%;
  height: calc(20.75em - 2.25em);
`;

const MultiBarsGraph = ({
  data,
  dataKey,
  min1,
  max1,
  min2,
  max2,
  unit1,
  unit2,
  name1,
  color1,
  key1,
  name2,
  color2,
  key2,
  name3,
  color3,
  key3,
}) => {
  const { colors } = useContext(ThemeContext);
  return (
    <GraphWrapper>
      <ResponsiveContainer>
        <BarChart
          width={applyEMRatio(540)}
          height={applyEMRatio(200)}
          data={data}
          barSize={applyEMRatio(6)}
          margin={{ top: applyEMRatio(16) }}
        >
          <CartesianGrid stroke={colors.graphGrid} vertical={false} />
          <XAxis dataKey={dataKey} tickLine={false} axisLine={false} />
          <YAxis
            yAxisId="left"
            orientation="left"
            tickLine={false}
            axisLine={false}
            domain={[min1, max1]}
            tickCount={5}
            unit={unit1}
            width={applyEMRatio(44)}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            tickLine={false}
            axisLine={false}
            domain={[min2, max2]}
            tickCount={5}
            unit={unit2}
            width={applyEMRatio(44)}
          />
          <Legend verticalAlign="top" height={applyEMRatio(64)} iconType="circle" />
          <Bar
            dataKey={key1}
            yAxisId="left"
            name={name1}
            fill={colors[color1 || "multiBars1"]}
            radius={[applyEMRatio(5), applyEMRatio(5), 0, 0]}
          />
          <Bar
            dataKey={key2}
            yAxisId="right"
            name={name2}
            fill={colors[color2 || "multiBars2"]}
            radius={[applyEMRatio(5), applyEMRatio(5), 0, 0]}
          />
          <Bar
            dataKey={key3}
            yAxisId="right"
            name={name3}
            fill={colors[color3 || "multiBars3"]}
            radius={[applyEMRatio(5), applyEMRatio(5), 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

export default MultiBarsGraph;
