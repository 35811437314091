import React from "react";
import Layout from "components/Layout";
import DropDown from "components/DropDown";
import useInput from "utils/useInput";
import ProgramSettings from "./ProgramSettings";
import ProgramDetails from "./ProgramDetails";
import Button from "components/Button";

const options = ["Program 1", "Program 2"];

const AdminGymProgramBuilder = () => {
  document.title = "Athleta Pro | Admin GYM Program Builder";
  const ProgramDropdown = useInput();
  const programNameInput = useInput("program-name");
  return (
    <Layout adminNav>
      <DropDown
        {...ProgramDropdown}
        placeholder="Program Dropdown"
        options={options}
        mw="28.750em"
      />
      <ProgramSettings programNameInput={programNameInput} />
      <ProgramDetails programName={programNameInput.value || "Program Name"} />
      <Button text="Generate Form" margin="3.75em 0 0 auto" />
    </Layout>
  );
};

export default AdminGymProgramBuilder;
