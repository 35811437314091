import React from "react";
import styled from "styled-components";
import Card from "components/Card";
import FormSideField from "components/FormSideField";
import Input from "components/Input";
import useInput from "utils/useInput";
import useToggle from "utils/useToggle";
import CheckBox from "components/CheckBox";
import Button from "components/Button";
import Flex from "components/Flex";

const Div = styled(Card)`
  margin-top: 3.75em;
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 40em;
    max-width: 48%;
  }
`;

const InputCreator = () => {
  const handleSubmit = (ev) => ev.preventDefault();
  const nameInput = useInput("name");
  const typeInput = useInput("type");
  const optionsInput = useInput("options");
  const [validation, toggleValidation] = useToggle();
  return (
    <Div as={Flex} fd="column" jc="space-between">
      <form id="InputCreator" onSubmit={handleSubmit}>
        <h3>Input Creator</h3>
        <FormSideField label="Name" text="Helper Text">
          <Input {...nameInput} />
        </FormSideField>
        <FormSideField label="Type" text="Helper Text">
          <Input {...typeInput} />
        </FormSideField>
        <FormSideField label="Options" text="Helper Text">
          <Input {...optionsInput} />
        </FormSideField>
        <FormSideField label="Show Validation">
          <CheckBox checked={validation} onChange={toggleValidation} label="Select" />
        </FormSideField>
      </form>
      <Button
        text="Create"
        type="submit"
        form="InputCreator"
        border="buttonBlue"
        margin="4em auto 0 auto"
      />
    </Div>
  );
};

export default InputCreator;
