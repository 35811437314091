import StatsIcon from "icons/StatsIcon";
import SquaresIcon from "icons/SquaresIcon";
import PenIcon from "icons/PenIcon";
import ListIcon from "icons/ListIcon";
import SettingsIcon from "icons/SettingsIcon";

export const adminList = [
  { text: "Dashboard", Icon: StatsIcon, to: "/admin" },
  { text: "Database", Icon: SquaresIcon, to: "/admin/database" },
  { text: "Form Builder", Icon: PenIcon, to: "/admin/form-builder" },
  { text: "Inputs", Icon: ListIcon, to: "/admin/inputs" },
  { text: "Gym Program Builder", Icon: ListIcon, to: "/admin/gym-program-builder" },
  { text: "Settings", Icon: SettingsIcon, to: "/admin/settings" },
];

export const playerList = [
  { text: "Dashboard", Icon: StatsIcon, to: "/player" },
  { text: "Inputs", Icon: ListIcon, to: "/player/inputs" },
  { text: "Gym Program", Icon: ListIcon, to: "/player/gym-program" },
  { text: "Settings", Icon: SettingsIcon, to: "/player/settings" },
];

const useNavList = ( isAdmin ) => (isAdmin ? adminList : playerList);

export default useNavList;
