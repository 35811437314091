import Home from "./Home";
import NotFound from "./NotFound";
import AdminDashboard from "./AdminDashboard";
import PlayerDashboard from "./PlayerDashboard";
import AdminDatabase from "./AdminDatabase";
import AdminFormBuilder from "./AdminFormBuilder";
import AdminInputs from "./AdminInputs";
import AdminGymProgramBuilder from "./AdminGymProgramBuilder";
import AdminSettings from "./AdminSettings";
import PlayerInputs from "./PlayerInputs";
import PlayerGymProgram from "./PlayerGymProgram";
import PlayerSettings from "./PlayerSettings";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/admin",
    component: AdminDashboard,
  },
  {
    path: "/admin/database",
    component: AdminDatabase,
  },
  {
    path: "/admin/form-builder",
    component: AdminFormBuilder,
  },
  {
    path: "/admin/inputs",
    component: AdminInputs,
  },
  {
    path: "/admin/gym-program-builder",
    component: AdminGymProgramBuilder,
  },
  {
    path: "/admin/settings",
    component: AdminSettings,
  },
  {
    path: "/player",
    component: PlayerDashboard,
  },
  {
    path: "/player/inputs",
    component: PlayerInputs,
  },
  {
    path: "/player/gym-program",
    component: PlayerGymProgram,
  },
  {
    path: "/player/settings",
    component: PlayerSettings,
  },
  {
    component: NotFound,
  },
];

export default routes;

/*
home (select admin or player)

admin
----------------
dashboard
database
form builder
inputs
gym program builder
! settings

player
-------------
! dashboard
inputs
gym program
! settings
*/
