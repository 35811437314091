import React from "react";
import styled from "styled-components";
import {
  players,
  teamTraining,
  posTraining,
  stackedData,
  composedData,
  pieData,
  multiBarsData,
} from "./individualBreakdowndata";
import useInput from "utils/useInput";
import DropDown from "components/DropDown";
import QuickDetails from "./QuickDetails";
import DualGraphs from "./DualGraphs";
import LongGraphs from "./LongGraphs";
import Flex from "components/Flex";
import LoadDistribution from "./LoadDistribution";
import WeekToWeek from "./WeekToWeek";

const Div = styled.div`
  margin-top: 6.5em;
`;

const Wrapper = styled.div`
  margin-top: 1em;
  @media ${({ theme }) => theme.breakpoints.sm} {
    max-width: 20em;
    margin: 0 auto;
  }
`;

const IndividualBreakdown = () => {
  const playerSelect = useInput("player", players[0].name);
  const options = players.map((p) => p.name);
  const selectedPlayer = players.find((p) => p.name === playerSelect.value);
  return (
    <Div>
      <h2>Individual Breakdown</h2>
      <Wrapper>
        <DropDown {...playerSelect} options={options} />
        <QuickDetails {...selectedPlayer} />
      </Wrapper>
      <DualGraphs
        player={selectedPlayer.training}
        team={teamTraining}
        position={posTraining[selectedPlayer.position]}
      />
      <LongGraphs data1={stackedData} data2={composedData} />
      <Flex fd="column" ai="center" md={{ fd: "row", ai: "stretch", jc: "space-evenly" }}>
        <WeekToWeek data={multiBarsData}/>
        <LoadDistribution data={pieData} />
      </Flex>
    </Div>
  );
};

export default IndividualBreakdown;
