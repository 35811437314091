import React from "react";
import styled from "styled-components";
import DropDown from "components/DropDown";
import Flex from "components/Flex";

const Div = styled(Flex)`
  p {
    text-align: center;
    opacity: 0.5;
    margin-bottom: 0.375em;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 6.25em;
  }
`;

const WeekDropDown = ({ label, ...props }) => {
  return (
    <Div fd="row" jc="space-between" sm={{ fd: "column", ai: "center", jc: "flex-start" }}>
      <p>{label}</p>
      <DropDown {...props} mw="100%" />
    </Div>
  );
};

export default WeekDropDown;
