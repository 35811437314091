import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Flex from "components/Flex";

const Div = styled(Flex)`
  height: 100vh;
  background: ${({ theme }) => theme.colors.bodyBg};
`;

const BigLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: ${({ theme }) => theme.colors.homeLink};
  background: ${({ theme }) => theme.colors.homeLinkBg};
  width: 10em;
  height: 4em;
  &:hover {
    background: ${({ theme }) => theme.colors.homeLinkHoverBg};
  }
`;

const Home = () => {
  document.title = "Athleta Pro | Home";
  return (
    <Div fd="column" jc="space-evenly" ai="center" sm={{ fd: "row" }}>
      <BigLink to="/admin">Admin</BigLink>
      <BigLink to="/player">Player</BigLink>
    </Div>
  );
};

export default Home;
