import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import Card from "components/Card";
import SimpleBarGraph from "graphs/SimpleBarGraph";

const Title = styled.div`
  opacity: 0.5;
`;

const Value = styled.h3`
  font-size: 1.5em;
  margin-top: 1em;
`;

const Change = styled.h6`
  font-size: 0.75em;
  color: ${({ theme, good }) => theme.colors[good ? "green" : "red"]};
`;

const DifferenceCard = ({ title, value, change, data }) => {
  const isChangeGood = parseInt(change) > 0;
  return (
    <Flex as={Card} jc="space-between" ai="flex-end">
      <div>
        <Title>{title}</Title>
        <Value>{value}</Value>
        <Change good={isChangeGood}>
          {isChangeGood ? "↑" : "↓"}&nbsp;
          {Math.abs(change)}%
        </Change>
      </div>
      <SimpleBarGraph data={data} />
    </Flex>
  );
};

export default DifferenceCard;
