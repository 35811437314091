import React from "react";
import Layout from "components/Layout";
import Card from "components/Card";
import styled from "styled-components";
import Flex from "components/Flex";
import ResponsiveTable from "components/ResponsiveTable";
import {
  table1Header,
  table1Body,
  table2Header,
  table2Body,
  player,
  training,
  posTraining,
} from "./gymProgramData";
import QuickDetails from "./QuickDetails";
import GymProgramGraph from "./GymProgramGraph";

const Wrapper = styled(Flex)`
  > * {
    flex: 1 1 50%;
  }
`;

const PlayerGymProgram = () => {
  document.title = "Athleta Pro | Player GYM Program";
  return (
    <Layout>
      <Wrapper fd="column" fhd={{ fd: "row" }}>
        <Card>
          <h3>Gym Program Name</h3>
          <ResponsiveTable header={table1Header} body={table1Body} noborder="yes" />
        </Card>
        <Card>
          <Flex fd="column" sm={{ fd: "row" }}>
            <QuickDetails {...player} />
            <GymProgramGraph player={training} position={posTraining} />
          </Flex>
          <ResponsiveTable header={table2Header} body={table2Body} />
        </Card>
      </Wrapper>
    </Layout>
  );
};

export default PlayerGymProgram;
