import React, { createContext, useState } from "react";

const initialState = { fullName: "John Doe" };

const userContext = createContext(initialState);

const UserContextProvider = ({ children }) => {
  const [userData, updateUser] = useState(initialState);
  return (
    <userContext.Provider
      value={{
        ...userData,
        updateUser,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export { userContext, UserContextProvider };
