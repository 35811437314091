import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import ThreeDotsIcon from "icons/ThreeDotsIcon";
import Card from "components/Card";

const IconWrapper = styled(Flex)`
  color: ${({ theme }) => theme.colors.dashboardOverviewIcon};
  border: solid 0.063em ${({ theme }) => theme.colors.dashboardOverviewIcon};
  border-radius: 100%;
  width: 2.25em;
  height: 2.25em;
  svg {
    font-size: 1.25em;
  }
`;

const TextWrapper = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin: 0 1.25em;
  p {
    color: ${({ theme }) => theme.colors.dashboardOverviewIcon};
    font-size: 1.125em;
  }
  small {
    font-size: 0.875em;
  }
`;

const More = styled.div`
  color: ${({ theme }) => theme.colors.dashboardOverviewIcon2};
`;

const StatusCard = ({ Icon, label, value }) => {
  return (
    <Card as={Flex} ai="center">
      <IconWrapper jc="center" ai="center">
        <Icon />
      </IconWrapper>
      <TextWrapper>
        <p>{value}</p>
        <small>{label}</small>
      </TextWrapper>
      <More>
        <ThreeDotsIcon />
      </More>
    </Card>
  );
};

export default StatusCard;
