import styled from "styled-components";

const objToCSS = ({ inline, ai, jc, fd, fw }) =>
  (inline ? "display: inline-flex;" : "")
    .concat(ai ? `align-items: ${ai};` : "")
    .concat(jc ? `justify-content: ${jc};` : "")
    .concat(fd ? `flex-direction: ${fd};` : "")
    .concat(fw ? `flex-wrap: ${fw};` : "");

const mapQueries = ({ theme: { breakpoints }, ...props }) =>
  Object.keys(breakpoints).reduce(
    (res, bp) =>
      props[bp] instanceof Object
        ? res.concat(`@media ${breakpoints[bp]} { ${objToCSS(props[bp])} }\n`)
        : res,
    ""
  );

const Flex = styled.div`
  display: flex;
  ${props => objToCSS(props)}
  ${props => mapQueries(props)}
`;

export default Flex;
