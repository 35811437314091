import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import RouteUpdates from "utils/RouteUpdates";
import routes from "routes/routes";

const Router = () => (
  <BrowserRouter>
    <RouteUpdates />
    <Switch>
      {routes.map((route, i) => (
        <Route {...route} key={i} exact />
      ))}
    </Switch>
  </BrowserRouter>
);

export default Router;
