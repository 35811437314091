import React, { memo } from "react";

const HelpIcon = (props) => (
  <svg width="1em" viewBox="0 0 10.614 10.614" {...props}>
    <path
      fillRule="evenodd"
      d="M10.614 5.307a5.307 5.307 0 1 1-1.555-3.753 5.307 5.307 0 0 1 1.554 3.752zM4.358 4h-.875a1.628 1.628 0 0 1 1.828-1.68c.927 0 1.773.484 1.773 1.486a1.619 1.619 0 0 1-.825 1.365c-.489.371-.67.509-.67.986v.235h-.868v-.307a1.511 1.511 0 0 1 .775-1.318c.391-.295.64-.488.64-.909a.771.771 0 0 0-.872-.775.846.846 0 0 0-.9.918zm.83 4.274a.621.621 0 1 1 0-1.238.622.622 0 1 1 0 1.238z"
    />
  </svg>
);

export default memo(HelpIcon);
