import React from "react";
import styled from "styled-components";
import { ResponsiveContainer, BarChart, Bar } from "recharts";
import applyEMRatio from "utils/applyEMRatio";

const GraphWrapper = styled.div`
  width: 7em;
  height: 2.5em;
  .main-stop {
    stop-color: ${({ theme }) => theme.colors.simpleBarGraph};
  }
  .end-stop {
    stop-color: ${({ theme }) => theme.colors.simpleBarGraphAlt};
  }
`;

const SimpleBarGraph = ({ data }) => (
  <GraphWrapper>
    <ResponsiveContainer>
      <BarChart width={applyEMRatio(112)} height={applyEMRatio(40)} data={data}>
        <defs>
          <linearGradient id="overviewBarsGradient" x1="0" y1="0" x2="0" y2="100%">
            <stop className="main-stop" offset="0%" />
            <stop className="end-stop" offset="100%" />
          </linearGradient>
        </defs>
        <Bar dataKey="val" fill="url(#overviewBarsGradient)" />
      </BarChart>
    </ResponsiveContainer>
  </GraphWrapper>
);

export default SimpleBarGraph;
