import { useState } from "react";

const useInput = (name = "", defaultValue = "") => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (ev) => setValue(ev.target.value);
  const placeholder = name
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return {
    name,
    value,
    // setValue,
    onChange,
    placeholder,
    minLength: name.includes("password") ? 8 : undefined,
    type: name.includes("password") ? "password" : name.includes("email") ? "email" : "text",
  };
};

export default useInput;
