import React from "react";
import styled from "styled-components";
import Card from "components/Card";
import FormSideField from "components/FormSideField";
import Input from "components/Input";
import useInput from "utils/useInput";
import useToggle from "utils/useToggle";
import CheckBox from "components/CheckBox";
import Button from "components/Button";
import Flex from "components/Flex";
import RadioInput from "components/RadioInput";

const Div = styled(Card)`
  margin-top: 3.75em;
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 40em;
    max-width: 48%;
    margin: 3.75em auto;
  }
`;

const WellnessForm = () => {
  const handleSubmit = (ev) => ev.preventDefault();
  const input1 = useInput("input1");
  const input2 = useInput("input2");
  const input3 = useInput("input3");
  const [validation, toggleValidation] = useToggle();
  const input5 = useInput("input5", "val1");
  return (
    <Div as={Flex} fd="column" jc="space-between">
      <form id="InputCreator" onSubmit={handleSubmit}>
        <h3>Wellness Form</h3>
        <FormSideField label="Input1" text="Helper Text">
          <Input {...input1} />
        </FormSideField>
        <FormSideField label="Input2" text="Helper Text">
          <Input {...input2} />
        </FormSideField>
        <FormSideField label="Input3" text="Helper Text">
          <Input {...input3} />
        </FormSideField>
        <FormSideField label="Input4">
          <CheckBox checked={validation} onChange={toggleValidation} label="Select" />
        </FormSideField>
        <FormSideField label="Input5">
          <RadioInput {...input5} value="val1" checked={input5.value === "val1"} />
          <RadioInput {...input5} value="val2" checked={input5.value === "val2"} />
          <RadioInput {...input5} value="val3" checked={input5.value === "val3"} />
          <RadioInput {...input5} value="val4" checked={input5.value === "val4"} />
          <RadioInput {...input5} value="val5" checked={input5.value === "val5"} />
        </FormSideField>
      </form>
      <Button text="Submit" type="submit" form="InputCreator" margin="4em auto 0 auto" />
    </Div>
  );
};

export default WellnessForm;
