import React from "react";
import DesktopTable from "./DesktopTable";
import MobileTable from "./MobileTable";

const ResponsiveTable = ({ update, selectId, ...props }) => {
  const handleChange = ({ target }) => update && update({ value: target.value, ...target.dataset });
  const updateId = (...props) => selectId && selectId(...props);
  return (
    <div>
      <MobileTable {...props} handleChange={handleChange} selectId={updateId} />
      <DesktopTable {...props} handleChange={handleChange} selectId={updateId} />
    </div>
  );
};

export default ResponsiveTable;
