import React from "react";
import Layout from "components/Layout";
import MainOverview from "./MainOverview";
import TeamOverview from "./TeamOverview";
import IndividualBreakdown from "./IndividualBreakdown";

const AdminDashboard = () => {
  document.title = "Athleta Pro | Admin Dashboard";
  return (
    <Layout adminNav>
      <MainOverview />
      <TeamOverview />
      <IndividualBreakdown />
    </Layout>
  );
};

export default AdminDashboard;
