import { makeArray, rnd } from "utils/dataGenerators";

const programData = {
  header: [
    { label: "Exercise Order", key: "order", size: "16.66%", editable: true },
    { label: "Exercise", key: "exercise", size: "16.66%", editable: true },
    { label: "Sets", key: "sets", size: "16.66%", editable: true },
    { label: "Reps", key: "reps", size: "16.66%", editable: true },
    { label: "TUT", key: "tut", size: "16.66%", editable: true },
    { label: "Rest", key: "rest", size: "16.66%", editable: true },
  ],
  weeks: makeArray(4).map((_, i) =>
    makeArray(6).map((_, id) => ({
      id,
      order: `A${id + 1}`,
      exercise: `Exercise ${rnd(1, 15)}`,
      sets: rnd(2, 10),
      reps: makeArray(4)
        .map((i) => rnd(2, 8))
        .join(","),
      tut: makeArray(2)
        .map((i) => rnd(1, 4))
        .join("X"),
      rest: `${rnd(30, 60)} Secs`,
    }))
  ),
};

export default programData;
