import React, { memo } from "react";

const SquaresIcon = (props) => (
  <svg height="1em" viewBox="0 0 14.851 14.851" {...props}>
    <path
      fillRule="evenodd"
      d="M6.854 1.142A1.142 1.142 0 0 0 5.712 0h-4.57A1.142 1.142 0 0 0 0 1.142v4.57a1.142 1.142 0 0 0 1.142 1.142h4.57a1.142 1.142 0 0 0 1.142-1.142zm-1.142 8h-4.57v4.57h4.57zm8 0H9.139v4.57h4.57zm0-8H9.139v4.57h4.57zm-8 0h-4.57v4.57h4.57zm2.285 0A1.142 1.142 0 0 1 9.139 0h4.57a1.142 1.142 0 0 1 1.142 1.142v4.57a1.142 1.142 0 0 1-1.142 1.142h-4.57a1.142 1.142 0 0 1-1.142-1.142zM1.142 7.997A1.142 1.142 0 0 0 0 9.139v4.57a1.142 1.142 0 0 0 1.142 1.142h4.57a1.142 1.142 0 0 0 1.142-1.142v-4.57a1.142 1.142 0 0 0-1.142-1.142zm8 0a1.142 1.142 0 0 0-1.145 1.142v4.57a1.142 1.142 0 0 0 1.142 1.142h4.57a1.142 1.142 0 0 0 1.142-1.142v-4.57a1.142 1.142 0 0 0-1.142-1.142z"
    />
  </svg>
);

export default memo(SquaresIcon);
