import React, { useState } from "react";
import styled from "styled-components";
import Card from "components/Card";
import ResponsiveTable from "components/ResponsiveTable";
import wellnessData from "./wellnessData";

const Div = styled(Card)`
  margin-top: 3.75em;
`;

const AddRow = styled.div`
  color: ${({ theme }) => theme.colors.dashboardOverviewIcon};
  margin: 0 auto;
  margin-top: 1em;
  text-align: center;
  span {
    cursor: pointer;
    font-size: 0.875em;
  }
`;

const WellnessForm = () => {
  const [data, setData] = useState(wellnessData.body);
  const update = ({ key, value, id }) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData[id][key] = value;
    setData(newData);
  };
  const [maxRows, setMaxRows] = useState(7);
  const addRow = () => setMaxRows(maxRows + 1);
  return (
    <Div>
      <h3>Wellness Form</h3>
      <ResponsiveTable header={wellnessData.header} body={data.slice(0, maxRows)} update={update} />
      {maxRows < data.length && (
        <AddRow>
          <span onClick={addRow}>Show More</span>
        </AddRow>
      )}
    </Div>
  );
};

export default WellnessForm;
