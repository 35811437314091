import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";

const Div = styled(Flex)`
  height: 2.5em;
  border-bottom: solid 0.063em ${({ theme }) => theme.colors.quickDetailsBorder};
  &:last-of-type {
    border: 0;
  }
`;

const IconWrapper = styled(Flex)`
  margin-right: 1.75em;
  background: ${({ theme }) => theme.colors.quickDetailsIconBg};
  color: ${({ theme }) => theme.colors.quickDetailsIcon};
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  svg {
    font-size: 0.75em;
  }
`;

const Label = styled.div`
  font-size: 0.875em;
`;

const Value = styled.div`
  font-size: 0.875em;
  opacity: 0.5;
`;

const QuickDetailItem = ({ Icon, label, value, ...props }) => (
  <Div ai="center" {...props}>
    <IconWrapper jc="center" ai="center">
      <Icon />
    </IconWrapper>
    <Label>{label}</Label>
    <div style={{ flex: "1 1 0", minWidth: "0" }} />
    <Value>{value}</Value>
  </Div>
);

export default QuickDetailItem;
