import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import DifferenceCard from "./DifferenceCard";
import mainOverviewData from "./mainOverviewData";
import SunIcon from "icons/SunIcon";
import UsersIcon from "icons/UsersIcon";
import StopWatchIcon from "icons/StopWatchIcon";
import StatusCard from "./StatusCard";

const Div = styled.div``;

const Row = styled(Flex)`
  > * {
    margin-top: 1.25em;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 1.25em;
    > * {
      flex-basis: 30%;
      max-width: 25em;
      margin: 0;
    }
  }
`;

const MainOverview = () => {
  return (
    <Div>
      <h2>Overview</h2>
      <Row fd="column" sm={{ fd: "row", jc: "space-between" }}>
        {mainOverviewData.diff.map((item) => (
          <DifferenceCard {...item} />
        ))}
      </Row>
      <Row fd="column" sm={{ fd: "row", jc: "space-between" }}>
        <StatusCard Icon={SunIcon} label="Wellness" value={mainOverviewData.wellness} />
        <StatusCard Icon={UsersIcon} label="Attendance" value={mainOverviewData.attendance} />
        <StatusCard
          Icon={StopWatchIcon}
          label="Game Availability"
          value={mainOverviewData.gameAvailability}
        />
      </Row>
    </Div>
  );
};

export default MainOverview;
