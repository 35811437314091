import React from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./GlobalStyles";
import "./App.css";
import Router from "router";
import { UserContextProvider } from "contexts/userContext";
import useDynamicTheme from "themes/useDynamicTheme";

const App = () => {
  const theme = useDynamicTheme();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <UserContextProvider>
        <Router />
      </UserContextProvider>
    </ThemeProvider>
  );
};

export default App;
