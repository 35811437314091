import React, { memo } from "react";
import styled from "styled-components";
import Flex from "components/Flex";

const Div = styled(Flex)`
  cursor: pointer;
  height: 2em;
  padding: 0 0.75em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: none;
  span {
    font-size: 0.875em;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.dropdownOptionHover};
  }
`;

const DropDownOption = ({ option, updateVal, ...props }) => {
  const onClick = () => updateVal(option);
  return (
    <Div ai="center" {...props} onClick={onClick}>
      <span>{option}</span>
    </Div>
  );
};

export default memo(DropDownOption);
