import React, { memo } from "react";

const StopWatchIcon = (props) => (
  <svg height="1em" viewBox="0 0 16.064 18.36" {...props}>
    <path
      fillRule="evenodd"
      d="M5.178.574A.574.574 0 0 1 5.75 0h4.59a.574.574 0 1 1 0 1.147H9.196v1.228a8.06 8.06 0 1 1-2.295 0V1.147H5.75a.574.574 0 0 1-.572-.573zM8.62 5.737a.574.574 0 0 0-1.147 0v4.017H4.03a.574.574 0 0 0 0 1.146h4.02a.574.574 0 0 0 .575-.574z"
    />
  </svg>
);

export default memo(StopWatchIcon);
