import React, { memo } from "react";

const CrossFilledIcon = (props) => (
  <svg height="1em" viewBox="0 0 16 16" {...props}>
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path d="M8 0a8 8 0 1 0 8 8 8.024 8.024 0 0 0-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8z" />
    </g>
  </svg>
);

export default memo(CrossFilledIcon);
