import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Flex from "components/Flex";

const Div = styled(Flex)`
  color: ${({ theme }) => theme.colors.logo};
  flex: 1 1 0;
  svg {
    height: 3.25em;
  }
  .alt {
    fill: ${({ theme }) => theme.colors.logoAlt};
  }
  height: ${({ sideMenu }) => (sideMenu ? "4.5em" : "")};
  @media ${({ theme }) => theme.breakpoints.lg} {
    flex: initial;
    min-width: 16.25em;
    max-width: 16.25em;
  }
`;

const Logo = ({ linkOnClick, ...props }) => (
  <Div jc={props.sideMenu ? "center" : "flex-start"} ai="center" lg={{ jc: "center" }} {...props}>
    <Link to="/" onClick={linkOnClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158.4 50">
        <path d="M66.7 15.2h-3.6L60.4 32h2.4l.5-3.3h3l.5 3.3h2.6l-2.7-16.8zm-3.2 11.2l1.2-8.3 1.2 8.3h-2.4zm55.1-11.2H115L112.3 32h2.4l.5-3.3h3l.5 3.3h2.6l-2.7-16.8zm-3.2 11.2l1.2-8.3 1.2 8.3h-2.4zm-43.8-8.8h-2.8v-2.4H77v2.4h-2.8V32h-2.6zm6.7-2.4h2.6V22h2.8v-6.8h2.6V32h-2.6v-7.6h-2.8V32h-2.6zm10.1 0H91v14.4h4.3V32h-7V15.2zm8.2 0h7.2v2.4h-4.6V22h3.6v2.4h-3.6v5.2h4.6V32h-7.2zm10.8 2.4h-2.8v-2.4h8.2v2.4H110V32h-2.6z" />
        <path
          className="alt"
          d="M130.3 15.2h-3.8V32h2.6v-6.8h1.2c2.6 0 3.9-1.5 3.9-4.2v-1.6c0-2.7-1.3-4.2-3.9-4.2zm1.3 6c0 1.2-.5 1.6-1.3 1.6h-1.2v-5.2h1.2c.8 0 1.3.4 1.3 1.6v2zm12 8.8v-3c0-1.9-.5-3.2-1.8-3.6 1.1-.5 1.7-1.6 1.7-3.3v-1c0-2.6-1.2-3.9-3.9-3.9h-3.8V32h2.6v-7.3h.9c1.2 0 1.7.5 1.7 2.1v3.1c0 1.4.1 1.7.2 2.1h2.7c-.3-.6-.3-1.2-.3-2zm-2.7-9.4c0 1.4-.6 1.8-1.6 1.8h-.9v-4.8h1.2c.9 0 1.3.5 1.3 1.7v1.3zm8.3-5.6c-2.6 0-4 1.5-4 4.2V28c0 2.7 1.4 4.2 4 4.2s4-1.5 4-4.2v-8.8c0-2.7-1.4-4.2-4-4.2zm1.3 13.2c0 1.2-.5 1.7-1.4 1.7-.9 0-1.4-.5-1.4-1.7V19c0-1.2.5-1.7 1.4-1.7.9 0 1.4.5 1.4 1.7v9.2z"
        />
        <path d="M44.1 18.5C41.6 9.9 32.8 3.8 22.8 5.7c-4 .8-7.4 2.7-10 5.7-4.8 5.6-6 12-3.4 19 .6 1.7 1.7 3.1 2.6 4.7 1.4-1.8 3-3.5 4.3-5.3 1.5-2 2.8-4.1 4.2-6.2 1-1.5 1.9-3.1 2.9-4.7.4-.7.2-1-.6-1-1.5-.1-3 0-4.5.4-1.4.5-2.9 1.1-4.3 1.6-.7.3-1.5.4-2.3.6l-.1-.2c.2-.1.3-.2.5-.3 1.3-.7 2.6-1.4 3.8-2.2 3.8-2.5 7.8-2.9 12-1.2 1.3.6 2.6 1.5 3.6 2.5 1.3 1.3 2.7 1.4 4.3 1 1.8-.5 3.5-1.2 5.2-1.8 0 0-.1.2-.3.3-1.9 1.5-3.9 2.7-6.3 3.3-1.5.4-3 .2-4.4-.7-.3-.2-.5-.3-.8-.5-.4-.2-.7-.2-1.1.3-1.3 1.5-2.7 3-4 4.5-.1.1-.3.2-.3.4-.1.2-.2.5-.4.7.3.1.5.2.8.3 1.3.3 2.5.6 3.8 1l.6.3c1.3.8 1.5 1.9.6 3.1-.6.8-1.4 1.4-2.1 2.1-1.1.9-2.4 1.6-3.6 2.6-.8.6-1.4 1.3-2.2 2l-.5.5c-.1 0-.1-.1-.2-.1.4-.7.7-1.4 1.2-2 1.1-1.2 2.2-2.3 3.4-3.5l.9-.9c.5-.6.3-1.2-.4-1.5-1.5-.6-3-.5-4.5-.4-.4 0-.8.4-1.2.7-2 1.7-3.9 3.4-5.8 5.2-.7.6-.7.6-1.3 0-.3-.3-.5-.5-.7-.8-.7.7-1.4 1.3-2 1.9-1.7 1.9-3.4 3.8-4.5 6.2-.1.3-.2.7-.3 1l.2.1c.5-.6.9-1.2 1.4-1.7 2-1.8 4-3.6 6-5.3.9-.7.7-.6 1.5.1 4.8 4.1 10.4 5.5 16.5 4 10.3-2.7 15.9-13.2 13.1-23zm-13.8-3c-1.3.5-2.5 0-2.8-1.4-.4-1.7 1.2-3.8 2.9-3.8 1.1 0 1.9.8 2 2 .1 1.3-.9 2.7-2.1 3.2z" />
      </svg>
    </Link>
  </Div>
);

export default Logo;
