import React from "react";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  display: none;
  border-collapse: collapse;
  margin-top: 1.25em;
  @media ${({ theme }) => theme.breakpoints.sm} {
    display: table;
  }
  td {
    font-size: 0.875em;
    text-align: center;
    white-space: pre;
    input {
      width: 100%;
      text-align: center;
    }
  }
  td:first-child {
    text-align: left;
    padding-left: 1.429em;
    input {
      text-align: left;
    }
  }
  td:last-child {
    text-align: right;
    padding-right: 1.429em;
    input {
      text-align: right;
    }
  }
  thead tr {
    background: ${({ theme }) => theme.colors.tableHeadBg};
    color: ${({ theme }) => theme.colors.tableHeadText};
    height: 2.875em;
    td {
      font-weight: bold;
    }
  }
  tbody {
    tr {
      border-bottom: ${({ theme, noborder }) =>
        noborder ? 0 : `solid 0.063em ${theme.colors.tableBorder}`};
      &.selected {
        border-bottom-color: ${({ theme }) => theme.colors.tableActive} !important;
        background: ${({ theme }) => theme.colors.tableSelected};
      }
    }
    td {
      padding: 1em 0;
    }
  }

  td.highlight {
    background: ${({ theme }) => theme.colors.tableHighlight};
    padding: 0 !important;
    text-align: center;
  }
`;

const DesktopTable = ({ header, body, handleChange, selectedId, selectId, ...props }) => (
  <Table {...props}>
    <thead>
      <tr>
        {header.map(({ label, key, size }) => (
          <td key={key} style={{ width: size }}>
            {label}
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      {body.map(({ id, ...values }) => (
        <tr
          key={id}
          className={id === selectedId ? "selected" : undefined}
          onClick={() => selectId(id)}
        >
          {header.map(({ key, highlight, editable }) => (
            <td key={key + id} className={highlight ? "highlight" : undefined}>
              {editable ? (
                <input value={values[key]} data-key={key} data-id={id} onChange={handleChange} />
              ) : (
                values[key]
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
);

export default DesktopTable;
