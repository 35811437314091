import React, { useContext } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from "recharts";
import styled, { ThemeContext } from "styled-components";
import applyEMRatio from "utils/applyEMRatio";

const GraphWrapper = styled.div`
  width: 100%;
  height: calc(20.75em - 2.25em);
`;

const PieGraph = ({ data, dataKey, text, ...props }) => {
  const { colors } = useContext(ThemeContext);
  return (
    <GraphWrapper {...props}>
      <ResponsiveContainer>
        <PieChart data={data} widh={applyEMRatio(460)} height={applyEMRatio(300)}>
          <Pie
            data={data}
            dataKey={dataKey}
            cy={window.innerWidth < 768 ? undefined : applyEMRatio(150)}
            cx={window.innerWidth < 768 ? undefined : applyEMRatio(120)}
            innerRadius={applyEMRatio(60)}
            outerRadius={applyEMRatio(120)}
          >
            {data.map((entry, index) => (
              <Cell fill={colors[`pie${index % 4}`]} strokeWidth={0} key={index} />
            ))}
          </Pie>
          {window.innerWidth < 768 ? (
            <Legend verticalAlign="top" iconType="circle" />
          ) : (
            <Legend
              verticalAlign="middle"
              layout="vertical"
              align="right"
              iconType="circle"
              wrapperStyle={{ top: "50%", transform: "translateY(-50%)" }}
            />
          )}
          <text
            x={window.innerWidth < 768 ? "50%" : applyEMRatio(125)}
            y={window.innerWidth < 768 ? applyEMRatio(160) : "52%"}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {text}
          </text>
        </PieChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

export default PieGraph;
