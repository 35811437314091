import styled from "styled-components";

const Card = styled.div`
  padding: 1.25em;
  margin: ${({ margin }) => margin};
  background: ${({ theme }) => theme.colors.cardBg};
  box-shadow: 0 0.375em 6px ${({ theme }) => theme.colors.cardShadow};
`;

export default Card;
