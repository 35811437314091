import React, { useState } from "react";
import Layout from "components/Layout";
import Tabs from "components/Tabs";
import Flex from "components/Flex";
import InputCreator from "./InputCreator";
import FormLayout from "./FormLayout";

const tabs = ["Wellness", "ACWR", "Attendance"];

const AdminFormBuilder = () => {
  const [activeTab, setTab] = useState(0);
  document.title = "Athleta Pro | Admin Form Builder";
  return (
    <Layout adminNav>
      <Tabs tabs={tabs} activeIndex={activeTab} update={setTab} />
      <Flex fd="column" sm={{ fd: "row", jc: "space-between" }}>
        <InputCreator />
        <FormLayout />
      </Flex>
    </Layout>
  );
};

export default AdminFormBuilder;
