import React from "react";
import styled from "styled-components";
import Card from "components/Card";
import Flex from "components/Flex";
import Input from "components/Input";
import CheckBox from "components/CheckBox";
import useToggle from "utils/useToggle";
import useInput from "utils/useInput";
import WeekDropDown from "./WeekDropDown";

const Div = styled(Card)`
  margin-top: 1.25em;
`;

const StyledInput = styled(Input)`
  margin-top: 1em;
  @media ${({ theme }) => theme.breakpoints.md} {
    margin: 0;
    max-width: 28em;
  }
`;

const MarginWrapper = styled(Flex)`
  margin: 0.5em -0.5em -0.344em -0.5em;
  > * {
    margin: 0.5em;
  }
`;

const ChecksWrapper = styled(Flex)`
  margin: 0.25em -0.75em -0.75em -0.75em;
  > * {
    margin: 0.75em;
  }
`;

const options = ["10%", "20%", "30%"];

const ProgramSettings = ({ programNameInput }) => {
  const [autoWeight, toggleAutoWeight] = useToggle();
  const [emptyWeight, toggleEmptyWeight] = useToggle();
  const week1DropDown = useInput("week1p", "10%");
  const week2DropDown = useInput("week2p", "10%");
  const week3DropDown = useInput("week3p", "10%");
  const week4DropDown = useInput("week4p", "10%");
  return (
    <Div>
      <h3>Program Settings</h3>
      <Flex fd="column" md={{ fd: "row", jc: "space-between", ai: "flex-end" }}>
        <StyledInput {...programNameInput} />
        <MarginWrapper fd="column" sm={{ fd: "row" }}>
          <WeekDropDown label="Week 1 %" options={options} {...week1DropDown} />
          <WeekDropDown label="Week 2 %" options={options} {...week2DropDown} />
          <WeekDropDown label="Week 3 %" options={options} {...week3DropDown} />
          <WeekDropDown label="Week 4 %" options={options} {...week4DropDown} />
        </MarginWrapper>
      </Flex>
      <ChecksWrapper fd="column" sm={{ fd: "row", fw: "wrap", jc: "flex-start" }}>
        <CheckBox
          checked={autoWeight}
          onChange={toggleAutoWeight}
          label="Automatically Calculate Weights?"
        />
        <CheckBox checked={emptyWeight} onChange={toggleEmptyWeight} label="Leave Weights Empty" />
      </ChecksWrapper>
    </Div>
  );
};

export default ProgramSettings;
