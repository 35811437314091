import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import { NavLink } from "react-router-dom";

const Link = styled(Flex)`
  color: ${({ theme }) => theme.colors.navText};
  padding: 0 1.25em;
  height: 3.25em;
  margin-bottom: 0.25em;
  border-left: 0.25em solid transparent;
  svg {
    margin-right: 0.75em;
  }
  &:not(.active):hover {
    color: ${({ theme }) => theme.colors.navTextHover};
  }
  &.active {
    color: ${({ theme }) => theme.colors.navActiveText};
    background: ${({ theme }) => theme.colors.navActiveBg};
    border-left: 0.25em solid ${({ theme }) => theme.colors.navActiveBorder};
  }
`;

const ListLink = ({ Icon, text, ...props }) => (
  <Link as={NavLink} exact {...props} ai="center">
    <Icon />
    <span>{text}</span>
  </Link>
);

export default ListLink;
