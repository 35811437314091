import React from "react";
import styled from "styled-components";
import useNavList from "./useNavList";
import ListLink from "./ListLink";

const Div = styled.div`
  max-width: 16.25em;
  min-width: 16.25em;
  background: ${({ theme }) => theme.colors.navBg};
  flex: 1 1 auto;
  display: none;
  @media ${({ theme }) => theme.breakpoints.lg} {
    display: block;
  }
`;

const Nav = ({ adminNav }) => {
  const list = useNavList(adminNav);
  return (
    <Div>
      {list.map((item, key) => (
        <ListLink {...item} key={key} />
      ))}
    </Div>
  );
};

export default Nav;
