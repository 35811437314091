import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  ComposedChart,
  Line,
} from "recharts";
import applyEMRatio from "utils/applyEMRatio";

const GraphWrapper = styled.div`
  width: 100%;
  height: calc(22em - 2.25em);
`;

const LineBarComposedChart = ({
  data,
  dataKey,
  color1,
  name1,
  key1,
  min1,
  max1,
  unit1,
  color2,
  name2,
  key2,
  min2,
  max2,
  unit2,
  ...props
}) => {
  const { colors } = useContext(ThemeContext);
  return (
    <GraphWrapper {...props}>
      <ResponsiveContainer>
        <ComposedChart width={applyEMRatio(1450)} height={applyEMRatio(320)} data={data}>
          <CartesianGrid stroke={colors.graphGrid} vertical={false} />
          <XAxis
            dataKey={dataKey}
            interval={data.length - 2}
            tickLine={false}
            axisLine={{ stroke: colors.graphGrid }}
            padding={{ left: applyEMRatio(8), right: applyEMRatio(8) }}
            dy={applyEMRatio(6)}
          />
          <Legend verticalAlign="top" align="left" height={applyEMRatio(40)} />
          <Bar
            radius={[applyEMRatio(10), applyEMRatio(10), 0, 0]}
            dataKey={key1}
            fill={colors[color1 || "composedBar"]}
            yAxisId="left"
            legendType="rect"
            name={name1}
            barSize={applyEMRatio(20)}
          />
          <Line
            dot={false}
            strokeWidth={applyEMRatio(2)}
            strokeLinecap="round"
            type="monotone"
            dataKey={key2}
            stroke={colors[color2 || "composedLine"]}
            yAxisId="right"
            legendType="rect"
            name={name2}
          />
          <YAxis
            tickLine={false}
            yAxisId="left"
            axisLine={{ stroke: colors.graphGrid }}
            unit={unit1}
            domain={[min1 || 0, max1 || "dataMax"]}
            tickCount={5}
            width={applyEMRatio(32)}
          />
          <YAxis
            tickLine={false}
            yAxisId="right"
            orientation="right"
            axisLine={{ stroke: colors.graphGrid }}
            unit={unit2}
            domain={[min2 || 0, max2 || "dataMax"]}
            tickCount={5}
            width={applyEMRatio(32)}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

export default LineBarComposedChart;
