import React, { useState } from "react";
import Layout from "components/Layout";
import Tabs from "components/Tabs";
import Filters from "./Filters";
import Wellness from "./Wellness";
import ButtonsCommands from "./ButtonsCommands";

const tabs = ["Wellness", "ACWR", "Attendance"];

const AdminDatabase = () => {
  const [activeTab, setTab] = useState(0);
  document.title = "Athleta Pro | Admin Database";
  return (
    <Layout adminNav>
      <Tabs tabs={tabs} activeIndex={activeTab} update={setTab} />
      <Filters />
      <Wellness />
      <ButtonsCommands />
    </Layout>
  );
};

export default AdminDatabase;
