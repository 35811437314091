import React, { useState } from "react";
import styled from "styled-components";
import Card from "components/Card";
import teamOverviewData from "./teamOverviewData";
import ResponsiveTable from "components/ResponsiveTable";

const Div = styled(Card)`
  margin-top: 2em;
`;

const AddRow = styled.div`
  color: ${({ theme }) => theme.colors.dashboardOverviewIcon};
  margin: 0 auto;
  margin-top: 1em;
  text-align: center;
  span {
    cursor: pointer;
    font-size: 0.875em;
  }
`;

const TeamOverview = () => {
  const [maxRows, setMaxRows] = useState(7);
  const addRow = () => setMaxRows(maxRows + 1);
  return (
    <Div>
      <h3>Team Overview</h3>
      <ResponsiveTable
        header={teamOverviewData.header}
        body={teamOverviewData.body.slice(0, maxRows)}
      />
      {maxRows < teamOverviewData.body.length && (
        <AddRow>
          <span onClick={addRow}>Show More</span>
        </AddRow>
      )}
    </Div>
  );
};

export default TeamOverview;
