import { rnd } from "utils/dataGenerators";

const generateData = () => {
  let arr = [];
  for (let id = 1; id < 12; id++)
    arr.push({
      id,
      player: `Player ${id}`,
      acwr: rnd(0.8, 1.2, false).toFixed(2),
      weakToWeak: `${Math.random() > 0.3 ? "+" : "-"}${rnd(2, 40)}%`,
      wellness: `${rnd(18, 43)}%`,
      attendance: `${rnd(60, 100)}%`,
    });
  return arr;
};

const teamOverviewData = {
  header: [
    { label: "Player", key: "player", size: "20%" },
    { label: "ACWR", key: "acwr", size: "20%" },
    { label: "Week-To-Week", key: "weakToWeak", size: "20%" },
    { label: "Wellness", key: "wellness", size: "20%" },
    { label: "Attendance", key: "attendance", size: "20%" },
  ],
  body: generateData(),
};

export default teamOverviewData;
