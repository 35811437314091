import lightColors from "./lightColors";

const white = "#FFF";
const black = "#000";

const darkColors = {
  ...lightColors,
  // Global Styles

  bodyBg: black,
  bodyColor: white,

  heading: "#dcd9d4",

  headerBg: "#181a1b",
  headerShadow: "rgba(255, 255, 255, 0.05)",
  headerUsername: "#c8c4bc",
  headerChevron: "#b7b2a8",

  logo: white,

  navBg: "#1b1c1e",
  navText: "#bab5ab",
  navActiveText: white,

  // Components
  cardBg: "#111115",
  cardShadow: "rgba(0, 0, 0, 0.1)",

  tableHeadBg: "#22222C",
  tableHeadText: "#b8b3a8",
  tableBorder: "#1D1D1D",

  dropdownBg: "#111115",
  dropdownBorder: "#22222C",
  dropdownListBorder: "#22222C",
  dropdownChevron: "#b7b2a8",
  dropdownOptionHover: "#1E2022",

  tabsBorder: "#303047",

  checkboxBorder: "#303047",

  inputBorder: "#22222C",
  inputBg: "transparent",
  inputPlaceholder: "#b7b2a8",

  buttonText: black,

  paginationBorder: "#22222C",
  paginationActiveText: black,

  // Graphs
  graphGrid: "#1E2022",

  composedBar: "#313436",

  stackedPurple: "#8A88FB",
  stackedAqua: "#3DD3FE",
  stackedRed: "#FF6C59",
  stackedYellow: "#FFD269",

  pie0: "#3DD3FE",
  pie1: "#8A88FB",
  pie2: "#FFD269",
  pie3: "#FF6C59",

  // Home
  homeLinkBg: "#111115",

  // Dashboard
  quickDetailsBorder: "#22222C",

  // Form Builder
  formLayoutFieldBorder: "#22222C",
};

export default darkColors;
