import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import PenIcon from "icons/PenIcon";
import CrossFilledIcon from "icons/CrossFilledIcon";

const Div = styled(Flex)`
  background: ${({ theme, selected }) => (selected ? theme.colors.formLayoutFieldSelected : "")};
  margin-top: 1.25em;
  height: 5em;
  padding: 0 1.5em;
  border-bottom: solid
    ${({ theme, selected }) =>
      theme.colors[selected ? "formLayoutFieldActive" : "formLayoutFieldBorder"]}
    0.063em;
  small {
    opacity: 0.5;
  }
  svg:first-of-type {
    color: ${({ theme }) => theme.colors.formLayoutFieldPen};
    margin: 0 0.875em;
    font-size: 0.875em;
  }
  svg {
    color: ${({ theme }) => theme.colors.formLayoutFieldCross};
  }
`;

const FormLayoutField = ({ ...props }) => {
  return (
    <Div jc="space-between" ai="center" {...props}>
      <p>Input Name</p>
      <div>
        <small>Input Type</small>
        <PenIcon />
        <CrossFilledIcon />
      </div>
    </Div>
  );
};

export default FormLayoutField;
