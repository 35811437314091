import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";

const Div = styled(Flex)`
  margin-top: 2.5em;
  > div {
    flex: 1 1 auto;
  }
`;

const P = styled.p`
  line-height: 2.5em;
  min-width: 7.5em;
`;

const Wrapper = styled(Flex)`
  height: 2.5em;
`;

const Small = styled.small`
  margin-top: 1em;
  display: block;
`;

const FormSideField = ({ label, text, children }) => {
  return (
    <Div>
      <P>{label}</P>
      <div>
        <Wrapper ai="center">{children}</Wrapper>
        {text && <Small>{text}</Small>}
      </div>
    </Div>
  );
};

export default FormSideField;
