import { getSeqDates, rnd } from "utils/dataGenerators";

const wellnessData = {
  header: [
    { label: "Date", key: "date", size: "20%" },
    { label: "Player Name", key: "name", size: "20%" },
    { label: "Readiness", key: "ready", size: "20%" },
    { label: "...", key: "dots", size: "20%" },
    { label: "TOTAL", key: "total", size: "20%" },
  ],
  body: getSeqDates(70, { year: "2-digit", month: "numeric", day: "numeric" }, "en-gb").map(
    (date, id) => ({
      id,
      date,
      name: `Player ${id + 1}`,
      ready: rnd(1, 5),
      dots: "...",
      total: `${rnd(40, 90)}%`,
    })
  ),
};

export default wellnessData;
