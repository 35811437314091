import React from "react";
import styled from "styled-components";
import Card from "components/Card";
import HelpIcon from "icons/HelpIcon";
import PersonIcon from "icons/PersonIcon";
import QuickDetailItem from "./QuickDetailItem";

const Div = styled(Card)`
  margin-top: 1em;
  h5 {
    margin-bottom: 1em;
  }
`;

const QuickDetails = ({ name, age, height, weight, position, status }) => (
  <Div>
    <h5>Quick Details</h5>
    <QuickDetailItem Icon={PersonIcon} label="Name" value={name} />
    <QuickDetailItem Icon={PersonIcon} label="Age" value={age} />
    <QuickDetailItem Icon={PersonIcon} label="Height" value={height} />
    <QuickDetailItem Icon={PersonIcon} label="Weight" value={weight} />
    <QuickDetailItem Icon={PersonIcon} label="Position" value={position} />
    <QuickDetailItem Icon={HelpIcon} label="Playing Status" value={status} />
  </Div>
);

export default QuickDetails;
