import React from "react";
import styled from "styled-components";

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 1.25em;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
  tr {
    height: 2.875em;
    border-bottom: solid 0.063em ${({ theme }) => theme.colors.tableBorder};
  }
  td {
    width: 50%;
    font-size: 0.875em;
  }
  input {
    width: 100%;
  }
  td:first-child {
    padding-left: 1.429em;
  }
  td:last-child {
    text-align: center;
    input {
      text-align: center;
    }
  }
  &:nth-of-type(odd) {
    background: ${({ theme }) => theme.colors.tableHeadBg};
  }
  &.selected tr {
    border-bottom-color: ${({ theme }) => theme.colors.tableActive} !important;
  }
  td.highlight {
    padding: 0 !important;
  }
`;

const MobileTable = ({ header, body, handleChange, selectedId, selectId }) =>
  body.map(({ id, ...values }) => (
    <Table
      key={id}
      className={id === selectedId ? "selected" : undefined}
      onClick={() => selectId(id)}
    >
      <tbody>
        {header.map(({ key, label, highlight, editable }) => (
          <tr key={key + id}>
            {!highlight && <td>{label}</td>}
            <td className={highlight ? "highlight" : undefined} colSpan={highlight ? 2 : undefined}>
              {editable ? (
                <input value={values[key]} data-key={key} data-id={id} onChange={handleChange} />
              ) : (
                values[key]
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ));

export default MobileTable;
