import React from "react";
import styled from "styled-components";
import StackedBarChart from "graphs/StackedBarChart";
import Card from "components/Card";
import LineBarComposedChart from "graphs/LineBarComposedChart";

const GraphCard = styled(Card)`
  margin-top: 1.25em;
`;

const LongGraphs = ({ data1, data2 }) => {
  return (
    <>
      <GraphCard>
        <h3>Wellness</h3>
        <StackedBarChart
          data={data1}
          dataKey="day"
          maxValue={1000}
          name1="Soreness"
          key1="d1"
          name2="Sleep Quality"
          key2="d2"
          name3="Sleep Duration"
          key3="d3"
          color4="stackedPurple"
          name4="Mood"
          key4="d4"
        />
      </GraphCard>
      <GraphCard>
        <h3>ACWR - RPE</h3>
        <LineBarComposedChart
          data={data2}
          dataKey="date"
          name1="Load"
          key1="d1"
          min1={5}
          max1={25}
          unit1="K"
          name2="ACWR"
          key2="d2"
          min2={5}
          max2={25}
          unit2="K"
        />
      </GraphCard>
    </>
  );
};

export default LongGraphs;
