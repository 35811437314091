import { createGlobalStyle } from "styled-components";

// Workaround for VSCODE extension bug
const css = createGlobalStyle;

const GlobalStyles = css`
  body {
    font-size: 0.85em;
    line-height: normal;
    background: ${({ theme }) => theme.colors.bodyBg};
    color: ${({ theme }) => theme.colors.bodyColor};

    @media ${({ theme }) => theme.breakpoints.md} {
      font-size: 1em;
    }

    @media ${({ theme }) => theme.breakpoints.qhd} {
      font-size: 1.25em;
    }

    @media ${({ theme }) => theme.breakpoints.uhd} {
      font-size: 1.75em;
    }
  }

  h2 {
    font-size: 1.75em;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.heading};
  }

  h3 {
    font-size: 1.125em;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.heading};
  }

  h4 {
    font-weight: normal;
    color: ${({ theme }) => theme.colors.heading};
  }

  h5,
  small {
    font-size: 0.875em;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.heading};
  }

  ::selection {
    color: ${({ theme }) => theme.colors.selectionColor};
    background: ${({ theme }) => theme.colors.selectionBg};
  }
`;

export default GlobalStyles;
