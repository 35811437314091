import React, { memo } from "react";

const PersonIcon = (props) => (
  <svg width="1em" viewBox="0 0 8.79 8.79" {...props}>
    <path
      fillRule="evenodd"
      d="M.733 8.79A.717.717 0 0 1 0 8.058c0-.733.733-2.93 4.4-2.93s4.4 2.2 4.4 2.93a.717.717 0 0 1-.733.733zM4.4 4.4a2.2 2.2 0 1 0-1.554-.644A2.2 2.2 0 0 0 4.4 4.4z"
    />
  </svg>
);

export default memo(PersonIcon);
