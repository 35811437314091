import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { userContext } from "contexts/userContext";
import ChevronIcon from "icons/ChevronIcon";
import Flex from "components/Flex";
import useToggle from "utils/useToggle";
import { Link } from "react-router-dom";
import Overlay from "components/DropDown/Overlay";

const Div = styled(Flex)`
  height: 100%;
  position: relative;
  cursor: ${({ listActive }) => (listActive ? "" : "pointer")};
  padding: 0 1em;
  small {
    margin-right: 1em;
    color: ${({ theme }) => theme.colors.headerUsername};
  }
  svg {
    color: ${({ theme }) => theme.colors.headerChevron};
    height: 0.438em;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    padding: 0 1.5em;
  }
`;

const List = styled(Flex)`
  min-width: 100%;
  position: absolute;
  top: calc(100% + 0.063em);
  right: 1em;
  z-index: 90;
  background: ${({ theme }) => theme.colors.navBg};
  box-shadow: 0 0.125em 0.188em ${({ theme }) => theme.colors.headerShadow};
  > * {
    cursor: pointer;
    padding: 1em;
    white-space: nowrap;
    text-transform: capitalize;
    &:hover {
      background: ${({ theme }) => theme.colors.navActiveBg};
      color: ${({ theme }) => theme.colors.navActiveText};
    }
  }
`;

const UserSide = () => {
  const { fullName } = useContext(userContext);
  const { altTheme, toggleTheme } = useContext(ThemeContext);
  const [active, toggle] = useToggle();
  return (
    <Div ai="center" onClick={toggle} listActive={active}>
      <small>{fullName}</small>
      <ChevronIcon />
      <Overlay active={active} onClick={toggle} />
      {active && (
        <List fd="column">
          <Link to="/profile">profile</Link>
          <p onClick={toggleTheme}>{altTheme} theme</p>
          <Link to="/logout">logout</Link>
        </List>
      )}
    </Div>
  );
};

export default UserSide;
