import { rnd, getSeqDates } from "utils/dataGenerators";

const positions = [
  "Goalkeeper",
  "Right Fullback",
  "Left Fullback",
  "Center Back",
  "Holding Midfielder",
  "Right Winger",
  "Central Midfielder",
  "Striker",
  "Attacking Midfielder",
  "Left Winger",
];

const randRadar = () => [
  { category: "Back Squat", val: rnd(30, 90) },
  { category: "Bench Press", val: rnd(30, 90) },
  { category: "Deadlift", val: rnd(30, 90) },
  { category: "Accessories", val: rnd(30, 90) },
  { category: "Pull Up", val: rnd(30, 90) },
  { category: "Bench Pull", val: rnd(30, 90) },
];

export const players = (() => {
  let arr = [];
  for (let i = 1; i < 12; i++)
    arr.push({
      name: `Player ${i}`,
      age: rnd(18, 32),
      height: `${rnd(170, 200)} cm`,
      weight: `${rnd(65, 80)} Kg`,
      position: positions[rnd(0, 9)],
      status: Math.random() > 0.3 ? "Healthy" : "Injured",
      training: randRadar(),
    });
  return arr;
})();

export const posTraining = (() =>
  positions.reduce((res, pos) => {
    res[pos] = randRadar();
    return res;
  }, {}))();

export const teamTraining = randRadar();

export const stackedData = (() =>
  getSeqDates(20, { weekday: "short" }).map((day) => ({
    day,
    d1: rnd(120, 200),
    d2: rnd(120, 200),
    d3: rnd(120, 200),
    d4: rnd(120, 200),
  })))();

export const composedData = (() =>
  getSeqDates(30, { year: "numeric", month: "long", day: "numeric" }).map((date) => ({
    date,
    d1: rnd(7, 22),
    d2: rnd(7, 18),
  })))();

export const pieData = [
  { name: "Field", val: rnd(200, 300) },
  { name: "Beach", val: rnd(200, 300) },
  { name: "Game", val: rnd(200, 300) },
  { name: "Gym", val: rnd(200, 300) },
];

export const multiBarsData = (() =>
  getSeqDates(7, { weekday: "short" }).map((day) => ({
    day,
    d1: rnd(15, 22),
    d2: rnd(30, 80),
    d3: rnd(30, 80),
  })))();
