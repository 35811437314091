import React, { useState } from "react";
import Layout from "components/Layout";
import Tabs from "components/Tabs";
import WellnessForm from "./WellnessForm";

const tabs = ["Wellness", "ACWR", "Attendance"];

const PlayerInputs = () => {
  const [activeTab, setTab] = useState(0);
  document.title = "Athleta Pro | Player Inputs";
  return (
    <Layout>
      <Tabs tabs={tabs} activeIndex={activeTab} update={setTab} />
      <WellnessForm />
    </Layout>
  );
};

export default PlayerInputs;
