import React from "react";
import styled from "styled-components";
import MenuIcon from "icons/MenuIcon";
import Flex from "components/Flex";
import Modal from "components/Modal";
import useToggle from "utils/useToggle";
import Logo from "components/Layout/Logo";
import useNavList from "components/Layout/useNavList";
import ListLink from "components/Layout/ListLink";

const Div = styled(Flex)`
  color: ${({ theme }) => theme.colors.headerUsername};
  margin-right: 1em;
  @media ${({ theme }) => theme.breakpoints.lg} {
    display: none;
  }
`;

const MobileList = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16.25em;
  z-index: 120;
  background: ${({ theme }) => theme.colors.headerBg};
`;

const MobileMenu = ({ adminNav }) => {
  const [active, toggle] = useToggle(false);
  const list = useNavList(adminNav);
  return (
    <Div jc="center" ai="center">
      <MenuIcon onClick={toggle} />
      <Modal active={active} toggle={toggle}>
        <MobileList>
          <Logo sideMenu linkOnClick={toggle} />
          {list.map((item, key) => (
            <ListLink {...item} key={key} onClick={toggle} />
          ))}
        </MobileList>
      </Modal>
    </Div>
  );
};

export default MobileMenu;
