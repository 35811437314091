import React from "react";
import styled from "styled-components";
import Flex from "./Flex";

const Btn = styled(Flex)`
  user-select: none;
  cursor: pointer;
  width: 2.25em;
  height: 2.25em;
  border-radius: 0.25em;
  margin: 0 0.25em;
  background: ${({ theme, active }) => active && theme.colors.paginationActive};
  color: ${({ theme, active }) => active && theme.colors.paginationActiveText};
  border: solid 0.063em ${({ theme, active }) => !active && theme.colors.paginationBorder};
  svg {
    transform: ${({ right }) => right && "rotate(180deg)"};
    stroke: ${({ theme }) => theme.colors.paginationArrows};
  }
  span {
    font-size: 0.875em;
    opacity: ${({ active }) => !active && 0.5};
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const B = ({ text, arrow, ...props }) => (
  <Btn jc="center" ai="center" {...props}>
    {arrow ? (
      <svg viewBox="0 0 8.669 13.694" height="0.875em">
        <path fill="none" d="M8.336.374L.769 7.105l7.567 6.2" />
      </svg>
    ) : (
      <span>{text}</span>
    )}
  </Btn>
);

const Pagination = ({ currentIndex, perPage, dataCount, update }) => {
  const pagesCount = Math.ceil(dataCount / perPage);
  const firstPage = () => update(0);
  const prevGroup = () => update(currentIndex - 5);
  const prev2Page = () => update(currentIndex - 2);
  const prevPage = () => update(currentIndex - 1);
  const nextPage = () => update(currentIndex + 1);
  const next2Page = () => update(currentIndex + 2);
  const nextGroup = () => update(currentIndex + 5);
  const lastPage = () => update(pagesCount - 1);
  return (
    <Flex>
      {currentIndex - 5 >= 0 ? (
        <B onClick={prevGroup} arrow />
      ) : (
        currentIndex !== 0 && <B onClick={firstPage} arrow />
      )}
      {currentIndex - 2 >= 0 && <B onClick={prev2Page} text={currentIndex - 1} />}
      {currentIndex - 1 >= 0 && <B onClick={prevPage} text={currentIndex} />}
      <B text={currentIndex + 1} active />
      {currentIndex < pagesCount - 1 && <B text={currentIndex + 2} onClick={nextPage} />}
      {currentIndex < pagesCount - 2 && <B text={currentIndex + 3} onClick={next2Page} />}
      {currentIndex < pagesCount - 5 ? (
        <B onClick={nextGroup} arrow right />
      ) : (
        currentIndex !== pagesCount - 1 && <B onClick={lastPage} arrow right />
      )}
    </Flex>
  );
};

export default Pagination;
