import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import Nav from "./Nav";
import Header from "./Header";

const Div = styled(Flex)`
  height: 100vh;
  width: 100vw;
`;

const Main = styled.main`
  flex: 1 1 0;
  min-height: 0;
  overflow: auto;
  padding: 1em;
  @media ${({ theme }) => theme.breakpoints.lg} {
    padding: 2em;
  }
  @media ${({ theme }) => theme.breakpoints.fhd} {
    padding: 3.5em 5.5em;
  }
`;

const Wrapper = styled(Flex)`
  flex: 1 1 0;
  min-height: 0;
`;

const Layout = ({ children, adminNav, ...props }) => (
  <Div fd="column" {...props}>
    <Header adminNav={adminNav} />
    <Wrapper>
      <Nav adminNav={adminNav} />
      <Main>
        {children}
        {/* Browser overflow bug workaround */}
        <div style={{ margin: "2em 0 0 0" }} />
      </Main>
    </Wrapper>
  </Div>
);

export default Layout;
