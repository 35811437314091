import React, { useState } from "react";
import Layout from "components/Layout";
import Tabs from "components/Tabs";
import WellnessForm from "./WellnessForm";
import Button from "components/Button";

const tabs = ["Wellness", "ACWR", "Attendance"];

const AdminInputs = () => {
  const [activeTab, setTab] = useState(0);
  document.title = "Athleta Pro | Admin Inputs";
  return (
    <Layout adminNav>
      <Tabs tabs={tabs} activeIndex={activeTab} update={setTab} />
      <WellnessForm />
      <Button text="Submit" type="submit" form="InputCreator" margin="2.5em 0 0 auto" />
    </Layout>
  );
};

export default AdminInputs;
