import React from "react";
import styled from "styled-components";
import ModalPortal from "./ModalPortal";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
  background: ${({ theme }) => theme.colors.modalBg};
  backdrop-filter: blur(5px);
`;

const Modal = ({ children, active, toggle, ...props }) => {
  if (active) {
    document.documentElement.style.overflow = "hidden";
    return (
      <ModalPortal>
        <Overlay onClick={toggle} />
        {children}
      </ModalPortal>
    );
  }
  document.documentElement.style.overflow = "auto";
  return null;
};

export default Modal;
