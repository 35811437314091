import React from "react";
import styled, { css } from "styled-components";
import RadarGraph from "graphs/RadarGraph";
import Card from "components/Card";
import Flex from "components/Flex";

const GraphCard = styled(Flex)`
  margin-top: 2em;
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 0;
    margin-left: 0.5em;
    flex: 1 1 calc(50% - 0.5em);
  }
`;

const graphCSS = css`
  height: calc(65vw - 4.5em);
  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 18em;
  }
  @media ${({ theme }) => theme.breakpoints.fhd} {
    height: 13em;
  }
`;

const GymProgramGraph = ({ player, position }) => {
  return (
    <GraphCard as={Card} ai="center">
      <RadarGraph
        data1={player}
        key1="val"
        color1="radarPurple"
        name1="Player"
        data2={position}
        key2="val"
        name2="Position"
        graphKey="category"
        maxValue={100}
        h="18em"
        hm="4.5em"
        css={graphCSS}
      />
    </GraphCard>
  );
};

export default GymProgramGraph;
