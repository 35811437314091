import { makeArray, rnd } from "utils/dataGenerators";

const positions = [
  "Goalkeeper",
  "Right Fullback",
  "Left Fullback",
  "Center Back",
  "Holding Midfielder",
  "Right Winger",
  "Central Midfielder",
  "Striker",
  "Attacking Midfielder",
  "Left Winger",
];

const randRadar = () => [
  { category: "Back Squat", val: rnd(30, 90) },
  { category: "Bench Press", val: rnd(30, 90) },
  { category: "Deadlift", val: rnd(30, 90) },
  { category: "Accessories", val: rnd(30, 90) },
  { category: "Pull Up", val: rnd(30, 90) },
  { category: "Bench Pull", val: rnd(30, 90) },
];

export const table1Header = [
  { label: "", key: "order", highlight: true, size: "3.125em" },
  { label: "Exercise", key: "exercise" },
  { label: "Reps", key: "reps", size: "16.66%" },
  { label: "Weights", key: "weights", size: "16.66%" },
  { label: "TUT", key: "tut", size: "16.66%" },
  { label: "Rest", key: "rest", size: "16.66%" },
];

export const table1Body = makeArray(5).map((id) => ({
  id,
  order: `A${id + 1}`,
  exercise: `Exercise ${rnd(1, 15)}`,
  reps: makeArray(4)
    .map((i) => rnd(2, 8))
    .join("\n"),
  weights: makeArray(4)
    .map((i) => rnd(20, 80))
    .join("\n"),
  tut: makeArray(2)
    .map((i) => rnd(1, 4))
    .join("X"),
  rest: `${rnd(30, 60)} Secs`,
}));

export const player = {
  name: `Player ${rnd(1, 11)}`,
  age: rnd(18, 32),
  height: `${rnd(170, 200)} cm`,
  weight: `${rnd(65, 80)} Kg`,
  position: positions[rnd(0, 9)],
  status: Math.random() > 0.3 ? "Healthy" : "Injured",
};

export const training = randRadar();

export const posTraining = randRadar();

export const table2Header = [
  { label: "Lift Name", key: "name" },
  { label: "Estimated 1RM", key: "estimate" },
  { label: "Current Ratio", key: "ratio" },
  { label: "Target", key: "target" },
];

export const table2Body = makeArray(5).map((id) => ({
  id,
  name: `Lift ${id + 1}`,
  estimate: `${rnd(50, 150)}Kg`,
  ratio: rnd(0.5, 1.5, false).toFixed(1),
  target: rnd(1, 9),
}));
