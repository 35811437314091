import styled from "styled-components";

const Input = styled.input`
  margin: ${({ margin }) => margin};
  font-size: 0.875em;
  width: ${({ w }) => w || "100%"};
  height: ${({ h }) => h || "2.857em"};
  padding: 0 1.286em;
  border: solid 0.071em ${({ theme }) => theme.colors.inputBorder};
  color: ${({ theme }) => theme.colors.inputActive};
  border-radius: 0.357em;
  background: ${({ theme }) => theme.colors.inputBg};
  &::placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceholder};
  }
  &:focus {
    border: solid 0.125em ${({ theme }) => theme.colors.inputActive};
  }
`;

export default Input;
