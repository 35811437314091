import { makeArray } from "utils/dataGenerators";

const wellnessData = {
  header: [
    { label: "Player Name", key: "name", size: "20%", editable: false },
    { label: "Input 1", key: "input1", size: "20%", editable: true },
    { label: "Input 2", key: "input2", size: "20%", editable: true },
    { label: "Input 3", key: "input3", size: "20%", editable: true },
    { label: "Input 4", key: "input4", size: "20%", editable: true },
  ],
  body: makeArray(10).map((_, id) => ({
    id,
    name: `Player ${id + 1}`,
    input1: "Data",
    input2: "Data",
    input3: "Data",
    input4: "Data",
  })),
};

export default wellnessData;
