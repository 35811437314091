import React from "react";
import styled from "styled-components";
import Flex from "./Flex";
import CheckIcon from "icons/CheckIcon";

const Label = styled(Flex)`
  display: inline-flex;
  margin: ${({ margin }) => margin};
  width: ${({ w }) => w};
  input {
    display: none;
  }
`;

const Dot = styled(Flex)`
  margin-right: 0.625em;
  width: 1.875em;
  height: 1.875em;
  border: solid 0.125em
    ${({ theme, checked }) => theme.colors[checked ? "checkboxActive" : "checkboxBorder"]};
  color: ${({ theme }) => theme.colors.checkboxActive};
  border-radius: 0.375em;
`;

const CheckBox = ({ label, checked, ...props }) => (
  <Label {...props} checked={checked} as="label" ai="center">
    <input {...props} type="checkbox" />
    <Dot jc="center" ai="center" checked={checked}>
      {checked && <CheckIcon />}
    </Dot>
    {label && <p>{label}</p>}
  </Label>
);

export default CheckBox;
