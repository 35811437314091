import React, { memo } from "react";
import styled from "styled-components";
import DropDownOption from "./DropDownOption";

const Div = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  border-radius: 0.25em;
  box-shadow: 0 0.125em 0.188em ${({ theme }) => theme.colors.dropdownShadow};
  background: ${({ theme }) => theme.colors.dropdownBg};
  border: solid 0.063em ${({ theme }) => theme.colors.dropdownListBorder};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  max-height: 10em;
  overflow: auto;
`;

const OptionsList = ({ open, options, value, ...props }) => (
  <Div {...props} open={open}>
    {options.map((option) => (
      <DropDownOption selected={option === value} key={option} option={option} {...props} />
    ))}
  </Div>
);

export default memo(OptionsList);
