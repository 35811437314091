import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Legend,
  PolarRadiusAxis,
} from "recharts";
import applyEMRatio from "utils/applyEMRatio";

const GraphWrapper = styled.div`
  width: 100%;
  height: calc(65vw - 2.25em);
  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 20.75em;
  }
  ${({ css }) => css}
`;

const RadarGraph = ({
  data1,
  key1,
  name1,
  color1,
  data2,
  key2,
  name2,
  color2,
  maxValue,
  graphKey,
  ...props
}) => {
  const { colors } = useContext(ThemeContext);
  return (
    <GraphWrapper {...props}>
      <ResponsiveContainer>
        <RadarChart
          width={applyEMRatio(468)}
          height={applyEMRatio(332)}
          data={data1}
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <PolarGrid stroke={colors.graphGrid} />
          <PolarAngleAxis dataKey={graphKey} />
          <PolarRadiusAxis domain={[0, maxValue || "dataMax"]} style={{ opacity: 0 }} />
          <Radar
            name={name1}
            dataKey={key1}
            stroke={colors[color1 || "radarRed"]}
            fill={colors[color1 || "radarRed"]}
            fillOpacity={0.3}
          />
          <Radar
            data={data2}
            name={name2}
            dataKey={key2}
            stroke={colors[color2 || "radarBlue"]}
            fill={colors[color2 || "radarBlue"]}
            fillOpacity={0.3}
          />
          <Legend verticalAlign="bottom" />
        </RadarChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

export default RadarGraph;
