import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import Flex from "components/Flex";

const Div = styled(Flex)`
  margin-top: 1.125em;
  > * {
    margin-top: 1em;
    flex: 1 1 auto;
    width: auto;
    margin-left: 1em;
    &:first-child {
      margin-left: 0;
    }
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 2.125em;
    > * {
      margin: 0;
      margin-left: 1.625em;
      width: 11.875em;
      flex: 0 0 auto;
    }
  }
`;

const ButtonsCommands = () => {
  return (
    <Div ai="center" jc="space-between" sm={{ fd: "row", jc: "flex-end" }}>
      <Button text="Delete" border="buttonRed" />
      <Button text="Edit" border="buttonBlue" />
      <Button text="Export to CSV" />
    </Div>
  );
};

export default ButtonsCommands;
