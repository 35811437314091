import React from "react";
import styled from "styled-components";
import Flex from "components/Flex";
import Card from "components/Card";
import RadarGraph from "graphs/RadarGraph";

const Div = styled(Flex)`
  margin-top: 2em;
  @media ${({ theme }) => theme.breakpoints.md} {
    margin-top: 4.125em;
  }
`;

const GraphCard = styled(Card)`
  margin-top: 2em;
  @media ${({ theme }) => theme.breakpoints.md} {
    width: 30.5em;
    max-width: 30.5em;
    margin: 0;
  }
`;

const DualGraphs = ({ player, team, position, ...props }) => {
  return (
    <Div {...props} fd="column" md={{ fd: "row", jc: "space-evenly" }}>
      <GraphCard>
        <RadarGraph
          data1={player}
          key1="val"
          name1="Player"
          data2={team}
          key2="val"
          name2="Team"
          graphKey="category"
          maxValue={100}
        />
      </GraphCard>
      <GraphCard>
        <RadarGraph
          data1={player}
          key1="val"
          name1="Player"
          data2={position}
          key2="val"
          name2="Position"
          graphKey="category"
          maxValue={100}
        />
      </GraphCard>
    </Div>
  );
};

export default DualGraphs;
